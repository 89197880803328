import * as React               from 'react';
import { GAuthCtx,
        UserNameDefault }       from '../../context_providers/AuthProvider';
import AlertBanner              from '../../components/alerts/AlertBanner';
import ChPassForcedInner        from './ChPassForcedInner';

interface IChPassForcedState {

}

export default class ChPassForced extends React.Component<{}, IChPassForcedState> {

  readonly state: IChPassForcedState = {

  };

  constructor(props: any) {
    super(props);
  }

  //
  // Rendering
  //

  public render(): JSX.Element {
    return (
      <GAuthCtx.Consumer>
        {(ctxAuth) => (
            <div className='account'>
              <div className='account__wrapper'>
                <div className='account__card'>
                  <div className='account__head'>

                    <h3 className='account__title'>Reset Your&nbsp;
                      <span className='account__logo'>
                        <span className='account__logo-accent'>Pass</span>word
                      </span>
                    </h3>
                    <h4 className='account__subhead subhead'>You need to set a new password to keep using Autocomms</h4>

                  </div>

                  <ChPassForcedInner
                    currUserName={ctxAuth.fnGetUsrName ? ctxAuth.fnGetUsrName() : UserNameDefault}
                    fnRstPassRstBeingForced={ctxAuth.fnRstPassRstBeingForced!}
                  />

                  <AlertBanner
                    color='info'
                    icon={true}
                    className="alert--bordered"
                  >
                    <h4 className='subhead'>
                      For your own security, you will occasionally be asked to reset your password
                      <br/><br/>
                      You will need to complete this step to continue using the system
                    </h4>
                  </AlertBanner>

                </div>
              </div>
            </div>
          )}
        </GAuthCtx.Consumer>
    )
  }
}
