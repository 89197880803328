import React                  from "react";
import { Route, Redirect }    from "react-router-dom";
import { GAuthCtx }           from "../context_providers/AuthProvider";

export const PrivateAdminRoute : React.FC<any> = ({ component: Component, ...rest }): JSX.Element =>
    <GAuthCtx.Consumer>
      {(ctx) => (
          <Route {...rest} render={(props) => (
            (ctx && ctx.isAuthenticated!() === true && ctx.isAdmin!())
              ? <Component {...props} />
              : <Redirect to="/callback" />
          )} />
        )}
    </GAuthCtx.Consumer>
