import * as React               from "react";
import MenuIcon                 from "mdi-react/MenuIcon";


interface ITopbarSidebarButtonProps {
  fnChSidebarVisibility      : () => void;  // Desktop toggle button action
  fnChSidebarVisibilityMobile: () => void;  // Mobile toggle button action
}

/**
 * This is the hamburger button when the screen is in full screen
 */
const TopbarSidebarButton: React.FC<ITopbarSidebarButtonProps> = (props): JSX.Element => {

  return (
    <div>
      <button className='topbar__button topbar__button--desktop' onClick={props.fnChSidebarVisibility}>
        <MenuIcon className='topbar__button-icon'/>
      </button>
      <button className='topbar__button topbar__button--mobile' onClick={props.fnChSidebarVisibilityMobile}>
        <MenuIcon className='topbar__button-icon'/>
      </button>
    </div>
  );
}

export default TopbarSidebarButton;
