import React               from "react";

interface StrengthIndicatorProps {
  strength       : number;    // 0 <= strength <= 100
  colorOverride? : "green" | "yellow" | "red" | "gray";
  showNumber?    : boolean;
}

const StrengthIndicator: React.FC<StrengthIndicatorProps> = ({strength, showNumber, colorOverride}): JSX.Element => {

  const classesOverridden = ( () => {
    switch(colorOverride) {
      case "green":
        return("dashboard__table-orders-amount")
      case "yellow":
        return("dashboard__table-orders-amount dashboard__table-orders-amount--medium");
      case "red":
        return("dashboard__table-orders-amount dashboard__table-orders-amount--low");
      case "gray":
        return("dashboard__table-orders-amount dashboard__table-orders-amount--gray");
      default:
        return(null);
    }
  })();

  const renderNumberText = (): JSX.Element | null =>
    showNumber ? <span>{strength}</span> : null

    if (strength > 80) {
      return(
        <div className={classesOverridden ? classesOverridden : "dashboard__table-orders-amount"}>
          <div/><div/><div/><div/><div/>
          {renderNumberText()}
        </div>
      );
    } else if(strength > 60) {
      return(
        <div className={classesOverridden ? classesOverridden : "dashboard__table-orders-amount"}>
          <div/><div/><div/><div/>
          {renderNumberText()}
        </div>
      );
    } else if(strength > 40) {
      return(
        <div className={classesOverridden ? classesOverridden : "dashboard__table-orders-amount dashboard__table-orders-amount--medium"}>
          <div/><div/><div/>
          {renderNumberText()}
        </div>
      );
    } else if(strength > 20) {
      return(
        <div className={classesOverridden ? classesOverridden : "dashboard__table-orders-amount dashboard__table-orders-amount--medium"}>
          <div/><div/>
          {renderNumberText()}
        </div>
      );
    } else {
      return(
        <div className={classesOverridden ? classesOverridden : "dashboard__table-orders-amount dashboard__table-orders-amount--low"}>
          <div/>
          {renderNumberText()}
        </div>
      );
    }
}

export default (StrengthIndicator);
