import * as React             from "react";
import { Route, Redirect }    from "react-router-dom";
import { GAuthCtx,
         UserNameDefault }    from "../context_providers/AuthProvider";


export const ChPassRoute = ({ component: Component, ...rest }: any) => (
    <GAuthCtx.Consumer>
      {(ctx) => (
          <Route {...rest} render={(props) => (
            (ctx.fnIsPassRstBeingForced!() === true && ctx.fnGetUsrName!() !== UserNameDefault)
              ? <Component {...props} />
              : <Redirect to='/callback' />
          )} />
        )}
    </GAuthCtx.Consumer>
)
