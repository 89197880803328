import React from "react";

interface PrivacyPolicyProps {
}

export const PrivacyPolicy: React.FC<PrivacyPolicyProps> = (props) => {
 return(
   <div style={{textAlign: "left"}}>
      <p>
        1 <i>https://gsd.autoinsights.co.uk</i> is committed to protecting your privacy and maintaining the security of any personal information received from you. We strictly adhere to the requirements of the data protection legislation in the UK.
        <br/>
        The purpose of this statement is to explain to you what personal information we collect and how we may use it.
      </p>

      <p>
        2 When you request access to the free resources we may ask you for your name, email address and telephone number.
      </p>

      <p>
        3 We may use your personal information to follow up and contact you about related and relevant products and services we provide at GoldSand Digital.
      </p>

      <p>
        4 We do not sell, rent or exchange your personal information with any third party for commercial reasons.
      </p>

      <p>
        5 We follow strict security procedures in the storage and disclosure of information which you have given us, to prevent unauthorised access in accordance with the UK data protection legislation.
        <br/>
        We do not collect sensitive information about you except when you specifically knowingly provide it. In order to maintain the accuracy of our database, you can check, update or remove your personal details by emailing us here: <i>info@goldsanddigital.com</i>
        <br/>
        We use a technology called <i>"cookies"</i> as part of a normal business procedure to track patterns of behaviour of visitors to our site. A cookie is an element of data that our Website sends to your browser which is then stored on your system. You can set your browser to prevent this happening. Any information collected in this way can be used to identify you unless you change your browser settings. However, if you choose to disable cookies there will be parts of this website that you will be unable to access.
      </p>

      <p>
        6 We will not transfer your information outside the EEA (European Economic Area) without first obtaining your consent.
      </p>

      <p>
        7 If you have any questions about privacy please contact us at <i>info@goldsanddigital.com</i>.
      </p>

      <p>
        8 We may update our privacy policy so please check to ensure you have read the latest version.
      </p>
   </div>
 );
}


