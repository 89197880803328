import React     from "react";
import DownIcon  from "mdi-react/ChevronDownIcon";

const TopBarExpandDownIcon: React.FC<{}> = (): JSX.Element => {

 return(
   <DownIcon className="topbar__avatar-icon"/>
 );
}

export default (TopBarExpandDownIcon);
