import React                          from "react";
import { Alert }                      from "reactstrap";
import InformationOutlineIcon         from "mdi-react/InformationOutlineIcon";
import AlertIcon                      from "mdi-react/AlertIcon";
import BombIcon                       from "mdi-react/BombIcon";
import TableLargeIcon                 from "mdi-react/TableLargeIcon";


interface AlertBannerProps {
  color      : "info" | "success" | "warning" | "danger",
  icon       : boolean,
  className  : "alert--bordered" | "alert--colored" | "alert--neutral" // we could leave this null for a non-iconified alert...but that's no fun
  onDismiss? : () => void;
}

export default class AlertBanner extends React.PureComponent<AlertBannerProps> {

  constructor(props: AlertBannerProps) {
    super(props);
  }

  public render(): JSX.Element {

    let Icon;

    switch (this.props.color) {
      case "info":
        Icon = <InformationOutlineIcon />;
        break;
      case "success":
        Icon = <TableLargeIcon />;
        break;
      case "warning":
        Icon = <AlertIcon />;
        break;
      case "danger":
        Icon = <BombIcon />;
        break;
      default:
        Icon = <AlertIcon />;
        break;
    }

    return (
      <Alert color={this.props.color} className={this.props.className} isOpen={true}>
        {this.props.icon && <div className='alert__icon'>{Icon}</div>}
        {
          this.props.onDismiss && <button className='close' onClick={this.props.onDismiss}><span className='lnr lnr-cross'/></button>
        }
        <div className='alert__content'>
          {this.props.children}
        </div>
      </Alert>
    );
  }
}
