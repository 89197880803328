import * as React           from "react";
import { Link }             from "react-router-dom";
import cn                   from "clsx";
import { AppProviderCtx,
         GAppCtx }          from "../../../context_providers/AppProvider";
import Consts               from "../../../shared/Consts";

interface ITopbarLogoProps {
  currUserName: string;
}

export const TopbarLogo: React.FC<ITopbarLogoProps> = (props): JSX.Element => {

  const { currUserName } = props;
  const appCtxValue  = React.useContext<AppProviderCtx>(GAppCtx);
  // const darkTheme    = "theme-dark" === appCtxValue.themeClassName;

  return(
    <Link className={cn("topbar__logo", {demo: Consts.USE_DEMO_BRAND})} to='/'/>
  );
}
