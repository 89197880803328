import * as React                  from "react";
// context providers
import { GAppCtx }                 from "../context_providers/AppProvider";

class MainWrapper extends React.PureComponent<{}> {

  public render(): JSX.Element {

    return (
      <GAppCtx.Consumer>
        {(ctx) => (
          <div className={ctx.themeClassName}>
            <div className={ctx.sidebarState.collapse ? "wrapper wrapper--full-width" : "wrapper"}>
              {this.props.children}
            </div>
          </div>
        )}
      </GAppCtx.Consumer>
    );

  }

}

export default MainWrapper;
