import React                 from "react";
import { PopOver }           from "./PopOver";
import { useSessionNumber }  from "../../hooks/HooksAuthCtx";
import { useToggleVal }      from "../../hooks/HooksGeneral";
import Consts                from "../../shared/Consts";

interface LoginInstructionProps {
  forceOn?            : boolean;
  forceClosed?        : boolean;
  sessionsToDisplayOn : number[];
  labelStyle?         : React.CSSProperties;
  body                : JSX.Element;
  title               : string;
  targetDomId         : string;
  placement           : "right" | "top" | "left" | "bottom";
  hideOnMobile?       : boolean;
}

const LoginInstruction: React.FC<LoginInstructionProps> = (props): JSX.Element => {

  const infoPopoverTimer                  = React.useRef<NodeJS.Timeout | null>(null);
  const sessionNumber                     = useSessionNumber();
  const {value      : infoPopupVisible,
         forceTrue  : infoPopupShow   ,
         forceFalse : infoPopupHide   }   = useToggleVal(false);

  const {forceOn, body, ...propsRest}           = props;

  React.useEffect( () => {

    if (props.sessionsToDisplayOn.includes(sessionNumber) && !(isMobileDontDisplay())) {
      infoPopoverTimer.current = setTimeout(openInfoPopover, 3000);  // just give everything a few seconds to settle
    }

    return () => {
      if (infoPopoverTimer.current) {
        console.log("[II] Cancel popover timer (open or close)");
        clearTimeout(infoPopoverTimer.current);
      }
    }

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect( () => {
    if (props.hideOnMobile && window.innerWidth >= Consts.SCREEN_WIDTHPX_HIDE_SIDEBAR) {
      window.addEventListener("resize", screenResized);
    }
    return () => {
      window.removeEventListener("resize", screenResized);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect( () => {
    if (props.forceClosed) {
      closeInfoPopover();
      window.removeEventListener("resize", screenResized);
    }
  }, [props.forceClosed]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Start a close timer every time we open the info popover
   */
  React.useEffect(() => {
    if (infoPopupVisible) {
      if (infoPopoverTimer.current) {
        clearTimeout(infoPopoverTimer.current);
      }
      infoPopoverTimer.current = setTimeout(closeInfoPopover, Consts.INFO_POPOVER_TIMEOUT_MS);
    }
  }, [infoPopupVisible]) // eslint-disable-line react-hooks/exhaustive-deps

  //
  // Info Popover
  //

  const openInfoPopover = () => {
    console.log("[DD] Opening login instruction popover");
    infoPopupShow();
  }

  const closeInfoPopover = () => {
    console.log("[DD] Closing login instruction popover");
    infoPopupHide();
  }

  //
  // Screen resizing / Mobiles
  //

  const isMobileDontDisplay = (): boolean => {
    return((props.hideOnMobile || false) && window.innerWidth < Consts.SCREEN_WIDTHPX_HIDE_SIDEBAR);
  }

  const screenResized = () => {

    if (isMobileDontDisplay()) {
      console.log("[DD] Mobile view on desktop popover");
      closeInfoPopover();
      window.removeEventListener("resize", screenResized);
    }
  }

  //
  // Rendering
  //

  const ammendedBody = (): JSX.Element => {
    return(<>
      {body}
      <div className="txt-got-it">
        Got it
      </div>
    </>);
  }

  return(
   <PopOver
      {...propsRest}
      triggerOnHover={false}
      hideTitle={true}
      isVisible={(props.forceOn) ? true : infoPopupVisible}
      className="popover-st"
      body={ammendedBody()}
    />
 );
}

export default (LoginInstruction);
