import React                        from "react";
// eslint-disable-next-line no-restricted-imports
import { Collapse }                 from "reactstrap";
import MinusIcon                    from "mdi-react/MinusIcon";
import PlusIcon                     from "mdi-react/PlusIcon";
import ChevronDownIcon              from "mdi-react/ChevronDownIcon";
import { useToggleVal }             from "../../hooks/HooksGeneral";
import classNames                   from "clsx";

interface ThemedCollapseProps {
  isOpenInitially : boolean;
  title           : string;
  className?       : "boxed" | "with-shadow";
}

type ViewState = {styleClass: string, icon: React.ReactNode}

export const ThemedCollapse: React.FC<ThemedCollapseProps> = ({isOpenInitially, title, className, children}): JSX.Element => {

  const { value : isOpen,
          toggle: toggleIsOpen } = useToggleVal(isOpenInitially);
  const [ view, setView ]        = React.useState<ViewState>({ styleClass: "closed", icon: <PlusIcon /> });

  const onEntering = () => {
    setView({ styleClass: "opening", icon: <MinusIcon /> });
  };

  const onEntered = () => {
    setView({ styleClass: "opened", icon: <MinusIcon /> });
  };

  const onExiting = () => {
    setView({ styleClass: "closing", icon: <PlusIcon /> });
  };

  const onExited = () => {
    setView({ styleClass: "closed", icon: <PlusIcon /> });
  };

  return(
    <div className={classNames("collapse__wrapper", view.styleClass, className)} dir="ltr">
      <button onClick={toggleIsOpen} className="collapse__title" type="button">
        {view.icon}
        <p>
          {title}
          <ChevronDownIcon />
        </p>
      </button>
      <Collapse
        isOpen={isOpen}
        className="collapse__content"
        onEntering={onEntering}
        onEntered={onEntered}
        onExiting={onExiting}
        onExited={onExited}
      >
        <div>
          {children}
        </div>
      </Collapse>
    </div>
  );
}
