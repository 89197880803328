import React                           from "react";
import { companiesGetForUsr,
         salesGetSummary,
         salesSetCompleteOnSale,
         salesSetDaysSpacingOnSale }   from "../services/BackendApi";
import { AvailActionsSummaryItem,
         CompanyDetailsLite,
         GetCompaniesResponse,
         ViewSaleAutomotiveAug }       from "../shared/interfaces_api";
import { toastToFailedLoadData }       from "../app/App";
import { Vulnerability }               from "../shared/interfaces_ac";
import { SusVal,
         useSuspensePromiseWrapper }   from "../hooks/HooksSuspense";
import update                          from "immutability-helper";
import * as Logging                    from "../shared/Logging";

//
// Types for the Context

type Summary = {
  loading           : boolean;                         // still find this to be much more flexible than using suspense
  salesSummary      : ViewSaleAutomotiveAug[];
  availActions      : AvailActionsSummaryItem[] | null;
  assignedCompanies : CompanyDetailsLite[] | null;     // yes, so we store this twice in this provider, it's fine
};

export type VulnSelection = "V1" | "V2" | "V3" | "V4";
type FilterPagination = {
  nameFilterVal   : string | null;
  vulnFilter      : VulnSelection | null;
  currPage        : number; // indexed from page 1
  includeComplete : boolean;
}

interface DataProviderState {
  summary                   : Summary;
  usersCompaniesResponse?   : SusVal<GetCompaniesResponse | null>
  filterPagination          : FilterPagination;
}

export interface DataProviderCtx extends DataProviderState {
  fnRefreshSummary?                : () => Promise<void>;
  fnRefreshUsersAssignedCompanies? : () => void;
  fnAlterCommsSpacing?             : (saleId: number, newSpacingInDays: number) => Promise<boolean>
  fnAlterIsComplete?               : (saleId: number, isComplete: boolean)      => Promise<boolean>
  fnSetFilterPagination?           : (newVal: FilterPagination) => void;
  fnRstPaginationPageNum?          : () => void;
}

const defaultProviderState: DataProviderCtx = {
  // it doesn't really matter what these are, as we'll never see them
  summary          : {loading: true, salesSummary: [], availActions: null, assignedCompanies: null},
  filterPagination : {nameFilterVal: null, vulnFilter: null, currPage: 1, includeComplete: false},
};

// the default doesn't have to take the shape of DataProviderState, but it's pretty convenient here to do so
export const DataCtx: React.Context<DataProviderCtx> = React.createContext(defaultProviderState);

const LogSource = "DataProvider";
export const DataProvider: React.FC<{}> = (props) => {

  const [summary, setSummary]                  = React.useState<Summary>(defaultProviderState.summary);
  const promiseWrapperUsersCompaniesResponse   = useSuspensePromiseWrapper<GetCompaniesResponse | null>();
  // users will sort, filter, and page through the results, so we need to keep this state
  const [filterPagination, setFilterPagination] = React.useState<FilterPagination>(defaultProviderState.filterPagination);

  const refreshSummary = async () => {
    const response = await salesGetSummary();
    if (response.success) {

      const summary    = response.data?.salesSummary ?? [];

      const summaryAug = summary.map( (it) => ({
        ...it,
        vulnerability : calcVulnerability(it.daysAgoLastAction, it.daysAfterLastActionEmailOpen, it.daysAfterLastActionEmailClick, it.daysAfterLastActionPageView, it.daysAgoFirstAction, it.lastMailBounced),
      } as ViewSaleAutomotiveAug));

      setSummary({
        loading           : false,
        salesSummary      : summaryAug,
        availActions      : response.data?.availActions      || null,
        assignedCompanies : response.data?.assignedCompanies || null,
      });
    } else {
      toastToFailedLoadData(response.statusCode);
    }
  }

  const rstPaginationPageNum = () => {
    setFilterPagination!({
      ...filterPagination,
      currPage: defaultProviderState.filterPagination.currPage,
    });
  }

  const calcVulnerability = (daysAgoLastAction            : number | null,
                             daysAfterLastActionEmailOpen : number | null,
                             daysAfterLastActionEmailClick: number | null,
                             daysAfterLastActionPageView  : number | null,
                             daysAgoFirstAction           : number | null,
                             lastContactBounced           : boolean) => {

    let vulnerability: Vulnerability = "neutral";

    const daysAfterLastActionEmailClickWithFallBack = daysAfterLastActionEmailClick ?? daysAfterLastActionPageView;

    if (lastContactBounced ?? false) {
      vulnerability = "contact issue";
    } else if (daysAgoLastAction) {
      if (null == daysAfterLastActionEmailOpen || null == daysAfterLastActionEmailClickWithFallBack) {
        if ((daysAgoFirstAction ?? 0) > 2) {
          // then they are not engaging with our mails...we haven't hooked/engaged the customer
          vulnerability = "less active";
          if ((daysAgoFirstAction ?? 0) > 5) {
            vulnerability = "vulnerable";
          }
        }
      } else {
        if (daysAfterLastActionEmailOpen < -2 || daysAfterLastActionEmailClickWithFallBack < -2) {
          // so they've opened or clicked on a prior message...but ignoring our latest messages
          vulnerability = "less active";
          // if (daysAfterLastActionEmailClickWithFallBack < -7) {  // dunno...we'll see if this fits
          //   vulnerability = "vulnerable";
          // }
        } else if (daysAfterLastActionEmailClickWithFallBack >= 0) {
          vulnerability = "engaged";
        }
      }
    } else {
      vulnerability = "not contacted";
    }

    return(vulnerability);
  }

  //
  // adj comms spacing on a sale

  const alterCommsSpacing = async (saleId: number, newSpacingInDays: number): Promise<boolean> => {

    const response = await salesSetDaysSpacingOnSale({saleId, daysSpacing: newSpacingInDays});

    if (response.success) {
      // let's just update our data locally to avoid needing a re-fetch
      const idx = summary.salesSummary.findIndex( (it) => it.id === saleId);
      Logging.assert(idx > -1, LogSource, `Tried to update a sale id that doesn't exist in the summary (${saleId})`);
      if (idx > -1) {
        setSummary({
          ...summary,
          salesSummary : update(summary.salesSummary, {[idx]: {daysSpacingOnComms: {$set: newSpacingInDays}}}),
        });
        return(true);
      }
    } else {
      toastToFailedLoadData(response.statusCode);
    }
    return(false);
  }

  const alterIsComplete = async (saleId: number, isComplete: boolean): Promise<boolean> => {

    const response = await salesSetCompleteOnSale({saleId, isComplete});

    if (response.success) {
      // let's just update our data locally to avoid needing a re-fetch
      const idx = summary.salesSummary.findIndex( (it) => it.id === saleId);
      Logging.assert(idx > -1, LogSource, `Tried to update a sale id that doesn't exist in the summary (${saleId})`);
      if (idx > -1) {
        setSummary({
          ...summary,
          salesSummary : update(summary.salesSummary, {[idx]: {completed: {$set: isComplete ? 1 : 0}}}),
        });
        return(true);
      }
    } else {
      toastToFailedLoadData(response.statusCode);
    }
    return(false);
  }

  //
  // users assigned companies

  const getUsersCompanies = async () => {
    const response = await companiesGetForUsr();
    if (response.success) {
      return (response.data ?? null);
    } else {
      toastToFailedLoadData(response.statusCode);
    }

    return(null);
  }

  const [usersCompaniesResponse, setUsersCompaniesResponse] = React.useState<SusVal<GetCompaniesResponse | null>>();
  const fnRefreshUsersAssignedCompanies = () => {
    setUsersCompaniesResponse( promiseWrapperUsersCompaniesResponse( getUsersCompanies() ) );
  }

  //
  // rendering
  return (
    <DataCtx.Provider value={{
      summary,
      fnRefreshSummary                : refreshSummary,

      fnRefreshUsersAssignedCompanies : fnRefreshUsersAssignedCompanies,
      usersCompaniesResponse,

      fnAlterCommsSpacing             : alterCommsSpacing,
      fnAlterIsComplete               : alterIsComplete  ,

      filterPagination,
      fnSetFilterPagination           : setFilterPagination,
      fnRstPaginationPageNum          : rstPaginationPageNum,
    }}>
      {props.children}
    </DataCtx.Provider>
  );
}
