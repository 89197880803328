
type Procedure = (...args: any[]) => void;

export default class Util {

  /**
   * if (score >  80) { return "strong"; }
   * if (score >  60) { return "good";   }
   * if (score >= 30) { return "weak";   }
   **/
  public static scorePassword = (password: string): number => {

    let score = 0;
    if (!password) {
      return score;
    }

    // should never contain the word password or pass
    if (password.toLowerCase().indexOf('pass') > -1 || password.toLowerCase().indexOf('password') > -1) {
      return score;
    }

    // award every unique letter until 5 repetitions
    let letters = new Object();
    for (let i=0; i<password.length; i++) {
      letters[password[i]] = (letters[password[i]] || 0) + 1;
      score += 5.0 / letters[password[i]];
    }

    // bonus points for mixing it up
    let variations = {
      digits: /\d/.test  (password),
      lower: /[a-z]/.test(password),
      upper: /[A-Z]/.test(password),
      nonWords: /\W/.test(password),
    }

    let variationCount = 0;
    for (let check in variations) {
        variationCount += (variations[check] == true) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return Math.round(score);
  }

  //
  // Debouncing
  //

  public static debounce = <F extends Procedure>(func: F                     ,
                                                 waitMilliseconds     = 50   ,
                                                 isImmediate: boolean = false): F => {
    let timeoutId: NodeJS.Timer | undefined;

    return function(this: any, ...args: any[]) {
      const context = this;

      const doLater = function() {
        timeoutId = undefined;
        if (!isImmediate) {
          func.apply(context, args);
        }
      }

      const shouldCallNow = isImmediate && (undefined === timeoutId);

      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(doLater, waitMilliseconds);

      if (shouldCallNow) {
        func.apply(context, args);
      }
    } as any
  }

}
