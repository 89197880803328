import * as React                          from "react";
import { useToggle }                       from "../../hooks/HooksGeneral";
import { Popover    ,
         PopoverBody,
         PopoverHeader }                   from "reactstrap";

interface IPopOverProps {
  title           : string;
  body            : JSX.Element;
  placement       : "right" | "top" | "left" | "bottom";
  targetDomId     : string;
  icon?           : JSX.Element;
  iconPlacement?  : "right" | "left";
  triggerOnHover  : boolean;
  hideTitle?      : boolean;    // by default the title is put into a <span>, hiding the title does not hide any icons
  isVisible?      : boolean;
  labelStyle?     : React.CSSProperties;
  className?      : string;
}

export const PopOver: React.FC<IPopOverProps> = (props): JSX.Element => {

  const [showPo , togglePo , setShowPo , unsetShowPo ] = useToggle(false);

  React.useEffect( () => {
    if (props.isVisible) {
      setShowPo();
    } else {
      unsetShowPo();
    }
  }, [props.isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  //
  // Rendering
  //

  const labelStyle = props.labelStyle ? {cursor: "pointer", ...props.labelStyle} : {cursor: "pointer"};

  const renderIcon = (): JSX.Element | null =>
    props.icon ? props.icon : null

  const renderLabel = (): JSX.Element | null => {

    return(
      props.hideTitle
      ?
        renderIcon()
      :
        <>
          {("left" === props.iconPlacement || undefined === props.iconPlacement) && renderIcon()}
          {props.title}
          {("right" === props.iconPlacement) && renderIcon()}
        </>
    );
  }

  return(
    <>
      <span id={props.targetDomId}
            onClick={togglePo}
            onMouseEnter={props.triggerOnHover ? setShowPo   : undefined}
            onMouseLeave={props.triggerOnHover ? unsetShowPo : undefined}
            style={labelStyle}
      >
        {renderLabel()}
      </span>
      <Popover placement={props.placement}
               modifiers={ { flip: { behavior: [props.placement] }, preventOverflow: {boundariesElement: "viewport"}} }
               isOpen={showPo}
               target={props.targetDomId}
               toggle={togglePo}
               onClick={unsetShowPo}
               className={props.className}
      >
        <PopoverHeader>
          {props.title}
        </PopoverHeader>
        <PopoverBody>
          {props.body}
        </PopoverBody>
      </Popover>
    </>
  );
}
