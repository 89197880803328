import React                        from "react";
import TopbarSidebarButton          from "./TopbarSidebarButton";
import TopbarProfile                from "./TopbarProfile";
import { GAppCtx }                  from "../../../context_providers/AppProvider";
import { TopbarLogo }               from "./TopbarLogo";
import TopbarCampaignSelector       from "./components/TopbarCampaignSelector";
// import TopbarMail               from './TopbarMail';
// import TopbarNotification       from './TopbarNotification';
// import TopbarSearch             from './TopbarSearch';

interface ITopbarProps {
  currUserName               : string;
  fnChSidebarVisibility      : () => void;  // Desktop toggle button action
  fnChSidebarVisibilityMobile: () => void;  // Mobile toggle button action
}

export default class Topbar extends React.PureComponent<ITopbarProps> {
  public render(): JSX.Element {
    return (
      <GAppCtx.Consumer>
        {(ctxApp) => (
          <div className="topbar">
            <div className="topbar__wrapper">
              <TopbarSidebarButton
                fnChSidebarVisibility={this.props.fnChSidebarVisibility}
                fnChSidebarVisibilityMobile={this.props.fnChSidebarVisibilityMobile}
              />
              <TopbarLogo
                currUserName={this.props.currUserName}
              />
              <div className="topbar__right">
                {/* <TopbarSearch/> */}
                {/* <TopbarNotification/> */}
                {/* <TopbarMail new={true} /> */}
                <TopbarCampaignSelector
                  isLoading={false}
                />
                <TopbarProfile
                  avatarUrl={ctxApp.avatarFilePath}
                  username={this.props.currUserName}
                />
              </div>
            </div>
          </div>
        )}
      </GAppCtx.Consumer>
    );
  }
}
