import React            from "react"
import Collapse         from "../../../components/collapse";
import { useToggleVal } from "../../../hooks/HooksGeneral";


interface SidebarCategoryProps {
  title               : string;
  icon?               : string;
  collapseStateInital?: boolean;
}

const SidebarCategory: React.FC<SidebarCategoryProps> = (props): JSX.Element | null => {

  const {value: collapse, toggle} = useToggleVal(props.collapseStateInital || false);

  return(
    <div className={`sidebar__category-wrap${collapse ? " sidebar__category-wrap--open" : ""}`}>
      <li className="sidebar__link sidebar__category" onClick={toggle}>
        {props.icon ? <span className={`sidebar__link-icon lnr lnr-${props.icon}`}/> : ""}
        <p className="sidebar__link-title">{props.title}</p>
        <span className="sidebar__category-icon lnr lnr-chevron-right"/>
      </li>
      <Collapse isOpen={collapse} className="sidebar__submenu-wrap">
        <ul className="sidebar__submenu">
          <div>
            {props.children}
          </div>
        </ul>
      </Collapse>
    </div>
  );

}

export default (SidebarCategory);
