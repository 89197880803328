import * as React              from "react";
// eslint-disable-next-line no-restricted-imports
import { Badge }               from "reactstrap";
import { NavLink }             from "react-router-dom";
// import { NavLink, withRouter } from "react-router-dom";

export interface ISidebarLinkProps {
  title  : string;
  route  : string;
  icon?  : string;
  new?   : boolean;
  onClick: () => void;
}

export default class SidebarLink extends React.Component<ISidebarLinkProps, {}> {

  constructor(props: any) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <NavLink to={this.props.route}
               onClick={this.props.onClick}
               activeClassName='sidebar__link-active'>
        <li className='sidebar__link'>
          {this.props.icon ? <span className={`sidebar__link-icon lnr lnr-${this.props.icon}`}/> : ""}
          <p className='sidebar__link-title'>
            {this.props.title}
            {this.props.new ? <Badge className='sidebar__link-badge'><span>New</span></Badge> : ""}
          </p>
        </li>
      </NavLink>
    )
  }
}

//export default withRouter(SidebarLink as any);
