import Consts from "./Consts";

export type LogLevel = "info" | "debug" | "warn" | "error";

const isProduction = process.env.NODE_ENV !== "development" || Consts.IS_PROD;

const logNop = (level: LogLevel) => (source: string, ...message: any[]) => {}

const logFun = (level: LogLevel) => (source: string, ...message: any[]) => {

  const levelChar = level[0].toUpperCase();

  const hasStringStart = typeof message[0] === "string";
  console[level](`[${levelChar.repeat(2)}] ${source}${hasStringStart ? " | " + message[0] : ""}`, ...message.splice(hasStringStart ? 1 : 0));
};

export const warn  = isProduction ? logNop("warn")  : logFun("warn");
export const info  = isProduction ? logNop("info")  : logFun("info");
export const debug = isProduction ? logNop("debug") : logFun("debug");
export const error = isProduction ? logNop("error") : logFun("error");
export const assert = (condition: boolean, source: string, ...message: any[]) => {
  if (!condition) {
    warn(source, ...message);
  }
};
