import * as React                     from "react";
import { Redirect }                   from "react-router-dom";
import { LoginResponse }              from "../shared/interfaces";
import Consts                         from "../shared/Consts";

/**
 * This provider will hold all of the states that drive the app template
 * and used to held in Redux (e.g. theme selection, sidebar toggled state, etc.)
 **/

 //
 // Types for the Wrapper Component
 //

interface IAuthProviderProps {

}

interface IAuthProviderState {

}

 //
 // Types for the Context
 //

export const UserNameDefault = "Nameless User";

export interface AuthProviderCtx extends IAuthProviderState {
  fnSetAuthSession?       : (loginResponse: LoginResponse) => void;
  handleAuthentication?   : () => void;
  fnSignOutOfSession?     : () => void;
  isAuthenticated?        : () => boolean;
  isAdmin?                : () => boolean;
  fnRstPassRstBeingForced?: () => void;
  fnIsPassRstBeingForced? : () => boolean;
  fnGetSessionNumber?     : () => number;

  fnGetUsrName?           : () => string;
}



const defaultProviderState: AuthProviderCtx = {
  // it doesn't really matter what these are, as we'll never see them
  fnSetAuthSession             : undefined,
  handleAuthentication         : undefined,
  fnSignOutOfSession           : undefined,
  isAuthenticated              : undefined,
  isAdmin                      : undefined,
  fnRstPassRstBeingForced      : undefined,
  fnIsPassRstBeingForced       : undefined,
  fnGetSessionNumber           : undefined,

  fnGetUsrName                 : undefined,
}

 // the default doesn't have to take the shape of IAuthProviderState, but it's pretty convenient here to do so
export const GAuthCtx: React.Context<AuthProviderCtx> = React.createContext(defaultProviderState);

export class AuthProvider extends React.Component<IAuthProviderProps, IAuthProviderState> {

  readonly state: IAuthProviderState = {

  }


  constructor(props: IAuthProviderProps){
    super(props);
  }

  componentDidMount() {

    const mountsForThis = this.getSessionNumber();
    if (mountsForThis >= 0 && mountsForThis < 100) {
      // will only be here for valid sessions
      localStorage.setItem(Consts.LOCALSTORE_SESSION_COUNT, `${mountsForThis + 1}`);
    }
  }

  //
  // Authentication Functionality
  //

  private signOut = () => {

    console.log("[II] Logout");

    localStorage.removeItem(Consts.LOCALSTORE_BEARER_TOKEN  );
    localStorage.removeItem("is_admin"                      );
    localStorage.removeItem("expires_at"                    );
    localStorage.removeItem("usr_name"                      );
    localStorage.removeItem(Consts.LOCALSTORE_THEME         );
    localStorage.removeItem(Consts.LOCALSTORE_CAMP_LAST_SELE);
    localStorage.removeItem(Consts.LOCALSTORE_RST_PASS      );
    localStorage.removeItem(Consts.LOCALSTORE_SESSION_COUNT );

    //window.location.href = '/'; // CHANGE to do this programatically
  }

  private handleAuthentication = () => {

    console.log("[II] Callback handler...");

    const isAuthenticated = this.isAuthenticated();

    if (isAuthenticated) {
      return (<Redirect to={Consts.ROUTE_DEFAULT} />);
    } else if ( this.isPassRstBeingForced() ) {
      return (<Redirect to='/rst_pass' />);
    } else {
      //window.location.href = '/log_in';
      return (<Redirect to='/log_in' />);
    }
  }

  private setSession = (loginResponse: LoginResponse) => {

    console.log("[II] Auth: Setting session");
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify(((loginResponse.expiresInSec - 60) * 1000) + new Date().getTime());

    localStorage.setItem(Consts.LOCALSTORE_BEARER_TOKEN , loginResponse.token                );
    localStorage.setItem("is_admin"                     , String(loginResponse.isAdmin)      );
    localStorage.setItem("expires_at"                   , expiresAt                          );
    localStorage.setItem("usr_name"                     , loginResponse.username             );
    localStorage.setItem(Consts.LOCALSTORE_RST_PASS     , String(loginResponse.forceChPasswd));
    localStorage.setItem(Consts.LOCALSTORE_SESSION_COUNT, String(0)                          );

    //return (<Redirect to='/main' />)
  }

  private isAuthenticated = (): boolean => {

    console.log("[II] Auth: checking auth...");
    if ( this.isPassRstBeingForced() ) {
      console.log("[WW] Auth: password reset required - user is not authenticated");
      return false;
    } else {
      // Check whether the current time is past the
      // Access Token's expiry time
      const expRaw = localStorage.getItem("expires_at");
      if (expRaw) {
        const expiresAt = JSON.parse(expRaw);
        console.log(`[II] Auth: looks okay, will return ${(new Date().getTime() < expiresAt)}`);
        return (new Date().getTime() < expiresAt);
      } else {
        console.log("[II] Auth: not authenticated");
        return false;
      }
    }
  }

  private isAdmin = (): boolean => {

    const isadminRaw = localStorage.getItem("is_admin");

    if (isadminRaw) {
      if ("true" === isadminRaw) {
        return true;
      }
    }

    return false;
  }

  /**
   * NOTE: If the key Consts.LOCALSTORE_RST_PASS is not found in local storage,
   *       we say that a password reset is NOT being forced, and let the user proceed
   **/
  private isPassRstBeingForced = (): boolean => {

    const isRstForced = localStorage.getItem(Consts.LOCALSTORE_RST_PASS);
    let bRet: boolean = false;

    if (isRstForced) {
      if ("true" === isRstForced) {
        bRet = true;
      }
    }

    return bRet;
  }

  private rstIsPassRstBeingForced = () => {
    localStorage.setItem(Consts.LOCALSTORE_RST_PASS, "false");
  }

  private getSessionNumber = (): number => {

    const mountsForThis = parseInt(localStorage.getItem(Consts.LOCALSTORE_SESSION_COUNT) || "-1", 10);

    if ( !(isNaN(mountsForThis)) ) {
      return(mountsForThis);
    }
    return(-1);
  }

  getUsrName = (): string => {
    return (localStorage.getItem("usr_name") || UserNameDefault);
  }

  //
  //
  //

  public render(): JSX.Element {
    return(
      // GAuthCtx will provide all the members of - AuthProviderCtx
      <GAuthCtx.Provider value={{
        isAuthenticated              : this.isAuthenticated                   ,
        isAdmin                      : this.isAdmin                           ,
        fnSetAuthSession             : this.setSession                        ,
        handleAuthentication         : this.handleAuthentication              ,
        fnSignOutOfSession           : this.signOut                           ,
        fnRstPassRstBeingForced      : this.rstIsPassRstBeingForced           ,
        fnIsPassRstBeingForced       : this.isPassRstBeingForced              ,
        fnGetUsrName                 : this.getUsrName                        ,
        fnGetSessionNumber           : this.getSessionNumber                  ,
      }}>
        {this.props.children}
      </GAuthCtx.Provider>
    );
  }

}

export default (AuthProvider);
