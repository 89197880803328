import React                        from "react";
import SidebarLink                  from "./SidebarLink";
import SidebarCategory              from "./SidebarCategory";
import Consts                       from "../../../shared/Consts";

interface SidebarContentProps {
  onClick             : () => void;
  fnSignOutOfSession  : () => void;
  fnChThemeLight      : () => void;
  fnChThemeDark       : () => void;
  fnChThemeCrisp      : () => void;
  fnShowAdminFeatures : () => boolean;
  allowPopovers?      : boolean;
}

const SidebarContent: React.FC<SidebarContentProps> = (props): JSX.Element => {

  //
  // Theming Togglers
  //

  const changeToDark = () => {
    props.fnChThemeDark();
    hideSidebar();
  };

  const changeToLight = () => {
    props.fnChThemeLight();
    hideSidebar();
  };

  // const changeToCrip = () => {
  //   props.fnChThemeCrisp();
  //   this.hideSidebar();
  // };

  /**
   * For desktop the onClick prop should be an empty function. For mobile, it should
   * set the show sidebar prop back to false, so we hide the sidebar after a menu selection
   **/
  const hideSidebar = () => {
    props.onClick();
  };

  const hideSidebarAndExit = () => {
    props.onClick();
    props.fnSignOutOfSession();
  };

  //
  // Rendering
  //

  /**
   * NOTE: Theme togglers don't use any routing, but are styled the same as the <NavLink/>s
   **/
  const renderVeryTopBlock = (): JSX.Element => {
    return (
      <ul className='sidebar__block'>
        <SidebarLink title="Summary"           route={Consts.ROUTE_DEFAULT} icon="home"          onClick={hideSidebar}/>
        <SidebarLink title="Analytics"         route="/ac/analytics"        icon="pie-chart"     onClick={hideSidebar}/>
        <SidebarLink title="Contact Schedule"  route="/ac/schedule"         icon="calendar-full" onClick={hideSidebar} />
        <SidebarCategory title='Themes' icon='layers'>
          <li className='sidebar__link' onClick={changeToLight}>
            <p className='sidebar__link-title'>Light Theme</p>
          </li>
          <li className='sidebar__link' onClick={changeToDark}>
            <p className='sidebar__link-title'>Dark Theme</p>
          </li>
          {/* <li className='sidebar__link' onClick={this.changeToCrip}>
            <p className='sidebar__link-title'>Crisp Theme</p>
          </li> */}
        </SidebarCategory>
      </ul>
    );
  }

  const renderAdminBlock = (): JSX.Element | null => {
    if (props.fnShowAdminFeatures()) {
      return(
        <ul className='sidebar__block'>
          <SidebarCategory title='Admin' icon="lock" key="sub-1">
            <SidebarLink title="Users"                  route="/admin/users"       onClick={hideSidebar} />
            <SidebarLink title="Companies"              route="/admin/companies"   onClick={hideSidebar} />
            <SidebarLink title="Tasks"                  route="/admin/tasks"       onClick={hideSidebar}/>
            <SidebarCategory title="Logs" key="sub-2">
              <SidebarLink title="Usage" route="/admin/logs/usage" onClick={hideSidebar}/>
              <SidebarLink title="Audit &amp; Errors"     route="/admin/logs/audit"       onClick={hideSidebar}/>
            </SidebarCategory>
            <SidebarLink title="App Settings"           route="/admin/appsettings" onClick={hideSidebar}/>
            {Consts.ENABLE_DEMO_SCR && <SidebarLink title="Create Demo Sale"       route="/admin/demo/new"    onClick={hideSidebar}/>}
            <SidebarLink title="Version History"        route="/admin/versions"    onClick={hideSidebar}/>
          </SidebarCategory>
        </ul>
      );
    } else {
      return null;
    }
  }

  const renderLogoutBlock = (): JSX.Element => {
    return (
      <ul className='sidebar__block'>
        <SidebarLink title='Log Out' icon='exit' route='/log_in' onClick={hideSidebarAndExit}/>
      </ul>
    );
  }

  //
  // Rendering
  //

  return (
    <div className='sidebar__content'>
      {renderVeryTopBlock()}
      {/* {renderPagesBlock()} */}
      {renderAdminBlock()}
      {renderLogoutBlock()}
    </div>
  );

}

export default (SidebarContent);
