import React               from "react";
// eslint-disable-next-line no-restricted-imports
import Scrollbar           from "react-smooth-scrollbar";
import classNames          from "clsx";
import { ScrollIndicator } from "./ScrollIndicator";
import ComponentTransition from "../transitions/ComponentTransition";
import { useToggleVal }    from "../../hooks/HooksGeneral";

interface ScrollBarProps {
  maxHeightVh?      : number;   // define for a custom scroll bar
  maxHeightPx?      : number;
  className?        : string;
  scrollIndicator?  : boolean;
  alwaysShowTracks? : boolean;
}

const ScrollBar: React.FC<ScrollBarProps> = ({maxHeightVh, maxHeightPx, className, scrollIndicator, alwaysShowTracks, children}): JSX.Element => {

  const { value     : scrollTouched,
          forceTrue : setScrollTouched } = useToggleVal();
  const { value      : scrollNeeded,
          forceFalse : rstScrollNeeded } = useToggleVal(true);
  const sb                               = React.useRef<Scrollbar | null>(null);
  const bottDivRef                       = React.useRef<HTMLDivElement | null>(null);

  React.useEffect( () => {
    if (sb.current) {
      const scrollSize    = sb.current!.scrollbar.getSize();
      const heightContent = scrollSize.content.height;
      const heightScroll  = scrollSize.container.height;

      if (heightContent <= heightScroll) {
        rstScrollNeeded();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToEnd = () => {
    if (sb.current && bottDivRef.current) {
      // bottDivRef.current.scrollIntoView({ behavior: "smooth", block: "end" }); // sb blocks this, need to use sb sdk
      sb.current.scrollbar.scrollIntoView(bottDivRef.current);
    }
  }

  return(<>

    <Scrollbar
      className={classNames("scroll", className)}
      style={(maxHeightVh && scrollNeeded) ? {height: `${maxHeightVh}vh`} : ( (maxHeightPx && scrollNeeded) ? {height: `${maxHeightPx}px`} : undefined)}
      ref={(instance) => sb.current = instance}
      onScroll={setScrollTouched}
      {...{alwaysShowTracks}}
    >
      {children}
      <div
        style={{backgroundColor: "transparent", width: "0", height: "0"}}
        ref={bottDivRef}
      />
    </Scrollbar>
    {
      ((scrollIndicator ?? true) && scrollNeeded) &&
      <ComponentTransition
        in={!(scrollTouched)}
        unmountOnExit={false}
      >
        <ScrollIndicator onClick={scrollToEnd} />
      </ComponentTransition>
    }
  </>);
}

export default (ScrollBar);
