import React from "react";

interface TermsProps {
}

const paraStyle = {marginTop:"15px"};

export const Terms: React.FC<TermsProps> = (props) => {
 return(
   <div style={{textAlign: "left"}}>
    <h6>1 ACCEPTANCE OF TERMS</h6>
    <p>
      Your access to and use of <i>https://gsd.autoinsights.co.uk</i> ("the Website") is subject exclusively to these Terms and Conditions.
      You will not use the Website for any purpose that is unlawful or prohibited by these Terms and Conditions. By using the Website
      you are fully accepting the terms, conditions and disclaimers contained in this notice. If you do not accept these Terms and
      Conditions you must immediately stop using the Website.
    </p>

    <h6 style={paraStyle}>2 ADVICE</h6>
    <p>
      The contents of the Website do not constitute advice and should not be relied upon in making or refraining from making, any decision.
    </p>

    <h6 style={paraStyle}>3 CHANGES TO WEBSITE</h6>
    <p>
      <i>https://gsd.autoinsights.co.uk</i> reserves the right to:
      <br/>
      3.1 change or remove (temporarily or permanently) the Website or any part of it without notice and you confirm that https://gsd.autoinsights.co.uk shall not be liable to you for any such change or removal; and
      <br/>
      3.2 change these Terms and Conditions at any time, and your continued use of the Website following any changes shall be deemed to be your acceptance of such change.
    </p>

    <h6 style={paraStyle}>4 LINKS TO THIRD PARTY WEBSITES</h6>
    <p>
      The Website may include links to third party websites that are controlled and maintained by others.
      Any link to other websites is not an endorsement of such websites and you acknowledge and agree that
      we are not responsible for the content or availability of any such sites.
    </p>

    <h6 style={paraStyle}>5 COPYRIGHT</h6>
    <p>
      5.1 All copyright, trade marks and all other intellectual property rights in the Website and its content (including without limitation the Website design, text, graphics and all software and source codes connected with the Website) are owned by or licensed to <i>https://gsd.autoinsights.co.uk</i> or otherwise used by <i>https://gsd.autoinsights.co.uk</i> as permitted by law.
      <br/>
      5.2 In accessing the Website you agree that you will access the content solely for your personal, non-commercial use. None of the content may be downloaded, copied, reproduced, transmitted, stored, sold or distributed without the prior written consent of the copyright holder. This excludes the downloading, copying and/or printing of pages of the Website for personal, non-commercial home use only.
    </p>

    <h6 style={paraStyle}>6 DISCLAIMERS AND LIMITATION OF LIABILITY</h6>
    <p>
      6.1 The Website is provided on an "AS IS" and "AS AVAILABLE" basis without any representation or endorsement made and without warranty of any kind whether express or implied, including but not limited to the implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security and accuracy.
      <br/>
      6.2 To the extent permitted by law, <i>https://gsd.autoinsights.co.uk</i> will not be liable for any indirect or consequential loss or damage whatever (including without limitation loss of business, opportunity
    </p>

   </div>
 );
}
