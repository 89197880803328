import * as React               from "react";
import ChartDonutIcon           from "mdi-react/ChartDonutIcon";
import ChartBarIcon             from "mdi-react/ChartBarIcon";
import ChartArcIcon             from "mdi-react/ChartArcIcon";
import LockQuestionIcon         from "mdi-react/LockQuestionIcon";
import LoginInner               from "./LoginInner";
import { GAuthCtx }             from "../../context_providers/AuthProvider";
import ModalWrapper             from "../../components/modal/ModalWrapper";

import { PrivacyPolicy }        from "./components/PrivacyPolicy";
import { Terms }                from "./components/Terms";
import { LoginLogo }            from "./components/LoginLogo";

interface ILogInProps {
}

interface ILogInState {
  termsModalIsOpen   : boolean;
  privacyModalIsOpen : boolean;
}

export default class LogIn extends React.PureComponent<ILogInProps,ILogInState> {

  readonly state: ILogInState = {
    termsModalIsOpen   : false,
    privacyModalIsOpen : false,
  };

  constructor(props: ILogInProps){
    super(props);
  }

  //
  //
  //

  private toggleTermsModal = () => {

    this.setState( (prevState) => ({
      termsModalIsOpen: !(prevState.termsModalIsOpen)
    }));
  }

  private togglePrivacyModal = () => {

    this.setState( (prevState) => ({
      privacyModalIsOpen: !(prevState.privacyModalIsOpen)
    }));
  }

  //
  // Rendering
  //

  // TODO - I should protect against someone clicking this whilst the login is occuring...
  private renderTermsModal = (): JSX.Element => {
    return(
      <ModalWrapper
        color='primary'
        hideBtn={true}
        header={false}
        title='Terms and Conditions'
        colored={false}
        cancelButtons={false}
        disabled={false}
        isOpen={this.state.termsModalIsOpen}
        large={true}
        toggleModal={this.toggleTermsModal}
        >
          <Terms />
      </ModalWrapper>
    );
  }

  private renderPrivacyModal = (): JSX.Element => {
    return(
      <ModalWrapper color='primary'
                    hideBtn={true}
                    header={false}
                    title='Privacy'
                    icon={LockQuestionIcon}
                    colored={false}
                    cancelButtons={false}
                    disabled={false}
                    isOpen={this.state.privacyModalIsOpen}
                    large={true}
                    toggleModal={this.togglePrivacyModal}
        >
          <PrivacyPolicy />
      </ModalWrapper>
    );
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        {this.renderTermsModal()}
        {this.renderPrivacyModal()}
        <GAuthCtx.Consumer>
          {(ctxAuth) => (
              <div
                className='account'
              style={{ background: "radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)" }}
              >

                {/*
                <div className='account__wrapper'>
                  <div className='account__card'>
                    <div className='account__head'>
                      <LoginLogo />
                    </div>

                    <LoginInner
                      fnSetAuthSession={ctxAuth.fnSetAuthSession!}
                    />

                    <div className='account__or'>
                      <p>
                        <ChartDonutIcon />
                        <ChartBarIcon />
                        <ChartArcIcon />
                      </p>
                    </div>
                    <div className='account__social'>
                      AutoComms
                    </div>
                  </div>
                </div>
                */}

                <ModalWrapper
                  color='primary'
                  hideBtn={true}
                  header={false}
                  title=''
                  // icon={LockQuestionIcon}
                  colored={false}
                  cancelButtons={false}
                  disabled={false}
                  isOpen={true}
                  large={false}
                  toggleModal={() => { }}
                  hideIcon={true}
                  hideCloseBtn={true}
                  borderRadius={true}
                  transparentBg={true}
                  noBackdrop={true}
                >
                  <LoginLogo />
                  <br />
                  <br />
                  <LoginInner
                    fnSetAuthSession={ctxAuth.fnSetAuthSession!}
                  />
                </ModalWrapper>


                <div
                  className="account__footer"
                  style={{position: "absolute", bottom: "0px", width: "100%", color: "white"}}
                >
                  {/* Provided by <a href="https://www.autocomms.co" target="_blank" className="login--footer-link">Autocomms Solutions LTD</a> &nbsp; &#124; &nbsp; */}
                  Provided by Autocomms Solutions LTD &nbsp; &#124; &nbsp;
                  &copy; 2023         &nbsp;
                  {/*
                  &#124; &nbsp;
                  <a onClick={this.toggleTermsModal} className="login--footer-link">Terms</a> &nbsp; &#124; &nbsp;
                  <a onClick={this.togglePrivacyModal} className="login--footer-link">Privacy</a>
                  */}
                </div>
              </div>
            )}
          </GAuthCtx.Consumer>
        </React.Fragment>
    )
  }
}
