import * as React                          from "react";
import Consts                              from "../../shared/Consts";
import { Button,
         ButtonToolbar }                   from "reactstrap";
import Spinner                             from "react-spinkit";
import {useQuery}                          from "react-query";
import Toggle                              from "react-toggle";
import { SetNotificationPrefReq,
         usrGetNotif }                     from "../../services/Backend";
import { useToggleVal }                    from "../../hooks/HooksGeneral";

interface ChNotifPrefsFormProps {
  disable     : boolean;
  submitForm  : (reqBody: SetNotificationPrefReq[]) => void;
  toggleModal : null | ( () => void );
}

/**
 * TODO could be a lot smarter with this form (i.e. make the form dynamically handle whatever \
 * fields come off the backend) but not really worth it for today....
 */
export const ChNotifPrefsForm: React.FC<ChNotifPrefsFormProps> = (props): JSX.Element => {

  const {
    isLoading : notifStatesLoading,
    isFetching: notifStatesReLoading,
    error     : notifStatesError,
    data      : notifStates,
    refetch   : notifStatesRefetch_,
  } = useQuery(["notifstates"], async () => {

      console.log("Checking user's notif states");

      const result = await usrGetNotif.submit({});
      if (result.data.success) {
        return(result.data);
      }

      console.error("Error fetching notif states");
      return(undefined);
    },
  );

  const isLoading   = notifStatesLoading || notifStatesReLoading;
  const disableCtls = isLoading || props.disable || undefined === notifStates;
  const {value: reportSalesVal, toggle: reportSalesToggle, setState: reportSalesSet} = useToggleVal(false);
  const {value: reportUsageVal, toggle: reportUsageToggle, setState: reportUsageSet} = useToggleVal(false);

  // -- lifecycle

  React.useEffect(() => {

    if (notifStates && notifStates.success) {
      if (notifStates.hasOwnProperty("emailSalesReport")) {
        reportSalesSet(notifStates.emailSalesReport)
      }
      if (notifStates.hasOwnProperty("emailUsageStats")) {
        reportUsageSet(notifStates.emailUsageStats ?? false);
      }
    }

  }, [notifStates]);

  // -- submit

  const submitForm = () => {

    if (notifStates) {
      const reqs: SetNotificationPrefReq[] = [];
      for (const setting of Object.keys(notifStates)) {
        if (setting !== "success") {
          // TODO this is kinda stupid, but it'll do for today
          if ("emailSalesReport" === setting) {
            reqs.push({reportName: setting, enabled: reportSalesVal});
          } else if ("emailUsageStats" === setting) {
            reqs.push({reportName: setting, enabled: reportUsageVal});
          }
        }
      }

      if (reqs.length > 0) {
        props.submitForm(reqs);
      }
    }
  }

  // -- rendering

  const renderFormControls = (): JSX.Element => {

    return(<>
      <div className="form__form-group">
        <label className="form__form-group-label" style={{width:"185px", textAlign:"right"}}>Periodic Sales Report Email</label>

        <div className="form__form-group-field" style={{width:"auto"}}>
          <Toggle
            disabled={disableCtls}
            checked={reportSalesVal}
            onChange={reportSalesToggle}
            icons={false}
          />
        </div>
      </div>
      {
        notifStates && notifStates.hasOwnProperty("emailUsageStats")
        ?
          <div className="form__form-group">
            <label className="form__form-group-label" style={{width:"185px", textAlign:"right"}}>Usage Report Email</label>

            <div className="form__form-group-field" style={{width:"auto"}}>
              <Toggle
                disabled={disableCtls}
                checked={reportUsageVal}
                onChange={reportUsageToggle}
                icons={false}
              />
            </div>
          </div>
        :
          null
      }
    </>);
  }

  return(
    <div className="dashboard">
      <form className="form form--horizontal">

        {renderFormControls()}

        <div className="form__form-group" style={{justifyContent: "center"}}>
          <ButtonToolbar className="modal__footer" style={{marginTop: "20px"}}>
            {
              null !== props.toggleModal
              ?
                <Button
                  size="sm"
                  type="button"
                  onClick={props.toggleModal}
                  disabled={disableCtls}>
                  Cancel
                </Button>
              :
                null
            }
            <Button
              color="primary"
              outline={(null !== props.toggleModal) ? true : false}
              style={(null !== props.toggleModal) ? {borderColor: "white", color: "white"} : {}}
              type="button"
              size="sm"
              onClick={submitForm}
              disabled={disableCtls}>
              Save
            </Button>
            {
              isLoading
              ?
                <Spinner name="double-bounce" color={(null !== props.toggleModal) ? Consts.ADDUSR_INDICATOR_COLOR : Consts.LOGGING_IN_INDICATOR_COLOR} />
              :
                <span style={{width: "27px"}} />
            }
          </ButtonToolbar>
          </div>
      </form>
    </div>
  );
}
