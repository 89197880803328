import * as React                         from "react";
//import TopbarMenuLink                   from "./TopbarMenuLink";
import TopbarMenuLinkLogOut               from "./TopbarMenuLinkLogOut";
import { DropdownItem ,
         DropdownToggle,
         DropdownMenu  ,
         UncontrolledDropdown }            from "reactstrap";
import ModalWrapper                        from "../../../components/modal/ModalWrapper";
import KeyVariantIcon                      from "mdi-react/KeyVariantIcon";
import AtIcon                              from "mdi-react/AtIcon";
import FountainPenIcon                     from "mdi-react/FountainPenIcon";
import ThumbsUpIcon                        from "mdi-react/ThumbsUpIcon";
import LanDisconnectIcon                   from "mdi-react/LanDisconnectIcon";
import { IChangePasswdRequest,
         IFeedbackRequest }                from "../../../shared/interfaces";
import ChPasswdForm                        from "../../../components/form_usr/ChPasswdForm";
import BackendApi, { usrChPassword }       from "../../../services/BackendApi";
import Notif                               from "../../../shared/Notif";
import AlertIcon                           from "mdi-react/AlertIcon";
import { FeedbackForm }                    from "../../../components/form_usr/FeedbackForm";
import TopBarExpandDownIcon                from "./components/TopBarExpandDownIcon";
import LoginInstruction                    from "../../../components/popover/LoginInstruction";
import Consts                              from "../../../shared/Consts";
import { SetNotificationPrefReq, usrSetNotif }          from "../../../services/Backend";
import { ChNotifPrefsForm }                from "../../../components/form_usr/ChNotifPrefsForm";

interface ITopbarProfileProps {
  avatarUrl: string;
  username : string;
}

interface ITopbarProfileState {
  chPasswordModelIsOpen    : boolean;
  submittingChPassReq      : boolean;
  chPasswdReq              : IChangePasswdRequest | null;

  chNotifPrefsModallIsOpen : boolean;
  submittingChNotifReq     : boolean;
  chNotifReq               : SetNotificationPrefReq[] | null;

  feedbackModalIsOpen      : boolean;
  submittingFeedback       : boolean;
  feedbackReq              : IFeedbackRequest | null;

  forceInfoPopupClosed     : boolean;
}

export default class TopbarProfile extends React.Component<ITopbarProfileProps, ITopbarProfileState> {

  readonly state: ITopbarProfileState = {
    chPasswordModelIsOpen    : false,
    submittingChPassReq      : false,
    chPasswdReq              : null ,

    chNotifPrefsModallIsOpen : false,
    submittingChNotifReq     : false,
    chNotifReq               : null ,

    feedbackModalIsOpen      : false,
    submittingFeedback       : false,
    feedbackReq              : null ,

    forceInfoPopupClosed     : false,
  };

  constructor(props: ITopbarProfileProps){
    super(props);
  }

  //
  // Login Instructions
  //

  private forceLoginInfoPopupClosed = () => {
    this.setState({ forceInfoPopupClosed: true});
  }

  //
  // Change Password
  //

  private toggleChPasswordModal = () => {

    this.setState( (prevState) => ({
      chPasswordModelIsOpen: !(prevState.chPasswordModelIsOpen),
    }));
  }

  private chUsrPassword = (reqBody: IChangePasswdRequest) => {

    this.setState({
      submittingChPassReq  : true,
      chPasswdReq          : reqBody,
    }, this.chUsrPassword_);
  }

  private chUsrPassword_ = async () => {

    if (this.state.chPasswdReq) {
      const response = await usrChPassword(this.state.chPasswdReq.oldpassword, this.state.chPasswdReq.newpassword);
      if (response.success) {
        Notif.notifInfo("Your password has been changed");
        this.setState({
          submittingChPassReq  : false,
          chPasswdReq          : null ,
          chPasswordModelIsOpen: false,
        });
      } else {
        const errMsg = response.data?.message || "An unexpected error occurred";
        Notif.notifError(<div><AlertIcon />{errMsg}</div>);
        this.setState({
          submittingChPassReq: false,
          chPasswdReq        : null ,
        });
      }
    }
  }

  //
  // Change Notification Preferences
  //

  private toggleChNotifPrefsModal = () => {

    this.setState( (prevState) => ({
      chNotifPrefsModallIsOpen: !(prevState.chNotifPrefsModallIsOpen),
    }));
  }

  private chNotifPrefs = (reqBody: SetNotificationPrefReq[]) => {

    this.setState({
      submittingChNotifReq : true,
      chNotifReq           : reqBody,
    }, this.chNotifPrefs_);
  }

  private chNotifPrefs_ = async () => {

    if (this.state.chNotifReq) {

      try {
        for (const changeReq of this.state.chNotifReq) {
          await usrSetNotif.submit({body: changeReq});
        }

        Notif.notifInfo("Your preferences have been saved");
        this.setState({
          submittingChNotifReq    : false,
          chNotifReq              : null ,
          chNotifPrefsModallIsOpen: false,
        });
      } catch (error) {
        console.error(error);
        Notif.notifError(<div><AlertIcon /> Error submitting request<br/>Please try again later</div>);
        this.setState({
          submittingChNotifReq: false,
          chNotifReq          : null ,
        });
      }
    }
  }

  //
  // Feedback Form
  //

  private toggleFeedbackModal = () => {

    this.setState( (prevState) => ({
      feedbackModalIsOpen: !(prevState.feedbackModalIsOpen),
    }));
  }

  private submitFeedback = (reqBody: IFeedbackRequest) => {

    this.setState({
      submittingFeedback: true   ,
      feedbackReq       : reqBody,
    }, this.submitFeedback_)
  }

  private submitFeedback_ = () => {

    if (this.state.feedbackReq) {
      BackendApi.usrFeedback(this.state.feedbackReq)
                .then(
                  (response: any) => {
                    //console.log(response.data);
                    if (undefined !== response.data && undefined !== response.data.success && true === response.data.success) {
                      Notif.notifSuccess(<div><ThumbsUpIcon /><br/>Thank you. Your feedback has been submitted.</div>);
                      this.rstFeedbackStateVars(true);
                    } else if (undefined !== response.data && undefined !== response.data.reason ) {
                      Notif.notifError(<div><AlertIcon /><br/>{response.data.reason}</div>);
                      this.rstFeedbackStateVars();
                    } else {
                      Notif.notifError(<div><AlertIcon /> An unexpected error occured<br/>Please try again later</div>);
                      this.rstFeedbackStateVars();
                    }
                  },
                  //(error: any) => { std HTTP errors wind up here (40x, 50x, etc.)
                )
                .catch((error: any) => {
                  console.error(error);
                  Notif.notifWarn(<div><LanDisconnectIcon /> There was a network error when submitting your feedback<br />You can try clicking submit again shortly</div>);
                  this.rstFeedbackStateVars();
                });
    }
  }

  private rstFeedbackStateVars = (exitWithSuccess?: boolean) => {

    if (exitWithSuccess) {
      this.setState({
        submittingFeedback : false,
        feedbackReq        : null ,
        feedbackModalIsOpen: false,
      });
    } else {
      this.setState({
        submittingFeedback: false,
        feedbackReq       : null ,
      });
    }
  }

  //
  // Rendering
  //

  private renderChangePasswordModal = (): JSX.Element => {
    return(
      <ModalWrapper color='primary'
                    hideBtn={true}
                    header={false}
                    title='Change Password'
                    colored={true}
                    icon={KeyVariantIcon}
                    cancelButtons={false}
                    disabled={this.state.submittingChPassReq}
                    isOpen={this.state.chPasswordModelIsOpen}
                    toggleModal={this.toggleChPasswordModal}
      >
        <ChPasswdForm
          currUserName={this.props.username}
          disable={this.state.submittingChPassReq}
          submitForm={this.chUsrPassword}
          toggleModal={this.toggleChPasswordModal}
        />
      </ModalWrapper>
    );
  }

  private renderChangeNotifModal = (): JSX.Element => {
    return(
      <ModalWrapper color='primary'
                  hideBtn={true}
                  header={false}
                  title='Change Email Preferences'
                  colored={true}
                  icon={AtIcon}
                  cancelButtons={false}
                  disabled={this.state.submittingChNotifReq}
                  isOpen={this.state.chNotifPrefsModallIsOpen}
                  toggleModal={this.toggleChNotifPrefsModal}
      >
        <ChNotifPrefsForm
          disable={this.state.submittingChNotifReq}
          submitForm={this.chNotifPrefs}
          toggleModal={this.toggleChNotifPrefsModal}
        />
      </ModalWrapper>
    );
  }

  // private renderFeedbackModal = (): JSX.Element => {
  //   return(
  //     <ModalWrapper color='primary'
  //                 hideBtn={true}
  //                 header={false}
  //                 title='AutoInsights Feedback'
  //                 colored={true}
  //                 icon={FountainPenIcon}
  //                 cancelButtons={false}
  //                 disabled={this.state.submittingFeedback}
  //                 isOpen={this.state.feedbackModalIsOpen}
  //                 toggleModal={this.toggleFeedbackModal}
  //     >
  //       <FeedbackForm
  //         currUserName={this.props.username}
  //         disable={this.state.submittingFeedback}
  //         submitForm={this.submitFeedback}
  //         toggleModal={this.toggleFeedbackModal}
  //       />
  //     </ModalWrapper>
  //   );
  // }

  private renderFeedbackPopover = (): JSX.Element => {
    return(
      <LoginInstruction
        sessionsToDisplayOn={Consts.POSHOWEVERY_FEEDBACK_PROMPT}
        labelStyle={{position: "absolute", bottom: "0", left:"50%", transform: "translateX(-50%)", WebkitTransform: "translateX(-50%)"}}
        body={<div>You can leave us feedback at anytime by selecting the <i>Feedback</i> option from this menu</div>}
        targetDomId="popoverFeedbackPrompt"
        title="Any Feedback?"
        placement="bottom"
        forceClosed={this.state.forceInfoPopupClosed}
      />
    );
  }

  public render(): JSX.Element {

    return(
      <React.Fragment>

        {this.renderChangePasswordModal()}
        {this.renderChangeNotifModal()}
        {/* {this.renderFeedbackModal()} */}

        <UncontrolledDropdown className='topbar__profile'>

          <DropdownToggle className='topbar__avatar' onClick={this.forceLoginInfoPopupClosed}>
            {this.renderFeedbackPopover()}
            <img width="36px" height="36px"
                className='topbar__avatar-img'
                src={this.props.avatarUrl} alt='avatar'
            />
            <p className='topbar__avatar-name'>
              {/* {ctxAuth.fnGetUsrName ? ctxAuth.fnGetUsrName() : UserNameDefault} */}
              {this.props.username}
            </p>
            <TopBarExpandDownIcon />
          </DropdownToggle>

          <DropdownMenu className='topbar__menu dropdown__menu'>
            {/*
            <DropdownItem>
              <TopbarMenuLink title='Page One' icon='user' path='/pages/one'/>
            </DropdownItem>
            <DropdownItem>
              <TopbarMenuLink title='Page Two' icon='calendar-full' path='/pages/two'/>
            </DropdownItem>
            <DropdownItem divider={true} className='topbar__menu-divider'/>
            */}

            <DropdownItem>
              <div className='topbar__link' onClick={this.toggleChPasswordModal}>
                <span className='topbar__link-icon lnr lnr-lock'/>
                <p className='topbar__link-title'>Change Password</p>
              </div>
            </DropdownItem>
            <DropdownItem>
              <div className='topbar__link' onClick={this.toggleChNotifPrefsModal}>
                <span className='topbar__link-icon lnr lnr-envelope'/>
                <p className='topbar__link-title'>Comms Preferences</p>
              </div>
            </DropdownItem>
            {/* <DropdownItem>
              <div className='topbar__link' onClick={this.toggleFeedbackModal}>
                <span className='topbar__link-icon lnr lnr-pencil'/>
                <p className='topbar__link-title'>Feedback</p>
              </div>
            </DropdownItem> */}
            <DropdownItem divider={true} className='topbar__menu-divider' />
            <DropdownItem>
              <TopbarMenuLinkLogOut title='Log Out' icon='exit' path='/log_in'/>
            </DropdownItem>
          </DropdownMenu>

        </UncontrolledDropdown>


      </React.Fragment>
    );
  }

}
