import * as React               from "react";
import { withRouter,
         RouteComponentProps }  from "react-router";
import classNames               from "clsx";
import SidebarContent           from "./SidebarContent";
import { ISideBarStates }       from "../../../context_providers/AppProvider";
import ScrollBar                 from "../../../components/scrollbar/ScrollBar";

type PathParamsType = {
  // param1: string;
}

type SidebarProps = RouteComponentProps<PathParamsType> & {
  sidebarState               : ISideBarStates;
  fnChSidebarVisibilityMobile: () => void;
  fnChThemeLight             : () => void;
  fnChThemeDark              : () => void;
  fnChThemeCrisp             : () => void;
  fnSignOutOfSession         : () => void;
  fnShowAdminFeatures        : () => boolean;
}

class Sidebar extends React.Component<SidebarProps,{}> {

  clickSidebarContentDesktop = () => {}

  //
  // Rendering
  //

  public render(): JSX.Element {

    const sidebarClass = classNames({
      "sidebar": true,
      "sidebar--show": this.props.sidebarState.show,
      "sidebar--collapse": this.props.sidebarState.collapse,
    });

    return (

          <div className={sidebarClass}>
            <div className='sidebar__back' onClick={this.props.fnChSidebarVisibilityMobile}/>
            <ScrollBar className="sidebar__scroll" scrollIndicator={false}>
              {/* -DESKTOP- */}
              <div className='sidebar__wrapper sidebar__wrapper--desktop'>
                <SidebarContent
                  fnSignOutOfSession={this.props.fnSignOutOfSession}
                  onClick={this.clickSidebarContentDesktop}
                  fnChThemeDark={this.props.fnChThemeDark}
                  fnChThemeLight={this.props.fnChThemeLight}
                  fnChThemeCrisp={this.props.fnChThemeCrisp}
                  fnShowAdminFeatures={this.props.fnShowAdminFeatures}
                  allowPopovers={true}
                />
              </div>
              {/* -MOBILE- */}
              <div className='sidebar__wrapper sidebar__wrapper--mobile'>
                <SidebarContent
                  fnSignOutOfSession={this.props.fnSignOutOfSession}
                  onClick={this.props.fnChSidebarVisibilityMobile}
                  fnChThemeDark={this.props.fnChThemeDark}
                  fnChThemeLight={this.props.fnChThemeLight}
                  fnChThemeCrisp={this.props.fnChThemeCrisp}
                  fnShowAdminFeatures={this.props.fnShowAdminFeatures}
                />
              </div>
            </ScrollBar>
          </div>

    );

  }

}

export default withRouter(Sidebar);
