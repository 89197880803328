import * as React               from "react";
import KeyVariantIcon           from "mdi-react/KeyVariantIcon";
import Consts                   from "../../shared/Consts";
import Notif                    from "../../shared/Notif";
import StrengthIndicator        from "../strength/StrengthIndicator";
import { Button,
         ButtonToolbar }        from "reactstrap";
import Util                     from "../../shared/Util";
import Spinner                  from "react-spinkit";
import { IChangePasswdRequest } from "../../shared/interfaces";

interface IChPasswdFormProps {
  disable     : boolean;
  submitForm  : (reqBody: IChangePasswdRequest) => void;
  toggleModal : null | ( () => void );
  currUserName: string;
}

interface IChPasswdFormState {
  password           : string;
  newPassword1       : string;
  newPassword2       : string;
  passwordErr        : boolean;
  newPasswordErr     : boolean;
  newPasswordCmplxErr: boolean;
}

export default class ChPasswdForm extends React.Component<IChPasswdFormProps, IChPasswdFormState> {

  readonly state: IChPasswdFormState  = {
    password           : "",
    newPassword1       : "",
    newPassword2       : "",
    passwordErr        : false,
    newPasswordErr     : false,
    newPasswordCmplxErr: false,
  };

  constructor(props: IChPasswdFormProps) {
    super(props);
  }

  //
  // Form Control
  //

  private chvalPassword = (event: any) => {
    this.setState({
      password   : event.target.value,
      passwordErr: false,
    });
  }

  private chvalPassword1 = (event: any) => {
    this.setState({
      newPassword1       : event.target.value,
      newPasswordErr     : false,
      newPasswordCmplxErr: false,
    });
  }

  private chvalPassword2 = (event: any) => {
    this.setState({
      newPassword2       : event.target.value,
      newPasswordErr     : false,
      newPasswordCmplxErr: false,
    });
  }

  //
  // Form Submission
  //

  private submitForm = () => {

    this.setState({
      passwordErr        : false,
      newPasswordErr     : false,
      newPasswordCmplxErr: false,
    }, this.submitForm_)
  }

  private submitForm_ = () => {

    if ( this.validateForm() ) {

      const reqBody: IChangePasswdRequest = {
        email       : this.props.currUserName.trim().toLowerCase(),
        oldpassword : this.state.password,
        newpassword : this.state.newPassword1,
      }

      this.props.submitForm(reqBody);

    } else {
      Notif.notifRainbow("Please correct any validation errors");
    }
  }

  private validateForm = (): boolean => {

    let bRet = true;

    // current password filled in?
    if (0 === this.state.password.length) {
      bRet = false;
      this.setState({
        passwordErr: true,
      })
    }

    if (0 === this.state.newPassword1.length || 0 === this.state.newPassword2.length) {
      // one of the new passwords are not filled in
      bRet = false;
      this.setState({
        newPasswordErr: true,
      })
    } else if (this.state.newPassword1 !== this.state.newPassword2) {
      // new passwords are not matching
      bRet = false;
      this.setState({
        newPasswordErr: true,
      })
    } else if (this.state.password.length > 0 && this.state.newPassword1 === this.state.password) {
      // new password same as old password
      bRet = false;
      this.setState({
        newPasswordErr: true,
      })
    } else if (Util.scorePassword(this.state.newPassword1) <= 60 || Util.scorePassword(this.state.newPassword2) <= 60) {
      // new password too easy to guess
      bRet = false;
      this.setState({
        newPasswordCmplxErr: true,
      })
    }

    return bRet;
  }

  //
  // Rendering
  //

  private renderPasswordStrength = (pass: string): JSX.Element | null => {

    if (pass) {
      const score = Util.scorePassword(pass);
      return <StrengthIndicator strength={score} />
      //if (score >  80) { return "strong"; }
      //if (score >  60) { return "good";   }
      //if (score >= 30) { return "weak";   }
    }

    return null;
  }

  private renderPasswdControls = (): JSX.Element => {

    const errorStyle           = {...Consts.inputErrorStyle, background: "white"}
    const restingStyle         = {background: "white"}

    return(
      <React.Fragment>

        <div className='form__form-group'>
          <label className='form__form-group-label'>Current Password</label>
          <div className='form__form-group-field'>
            <div className='form__form-group-icon'>
              <KeyVariantIcon />
            </div>
            <div className='form__form-group-input-wrap'>
              <div style={{...restingStyle, display:"flex", position:"relative"}}>
                <input
                  name='curr_password'
                  type='password'
                  value={this.state.password}
                  onChange={this.chvalPassword}
                  placeholder='Current Password'
                  disabled={this.props.disable}
                  style={this.state.passwordErr ? errorStyle: restingStyle}
                />
              </div>
              {this.state.passwordErr ? <span className='form__form-group-error'>Please enter your current password</span> : null}
            </div>
          </div>
        </div>

        <div className='form__form-group'>
          <label className='form__form-group-label'>New Password</label>
          <div className='form__form-group-field'>
            <div className='form__form-group-icon'>
              <KeyVariantIcon />
            </div>
            <div className='form__form-group-input-wrap'>
              <div style={{...restingStyle, display:"flex", position:"relative"}}>
                <input
                  name='new_password1'
                  type='password'
                  value={this.state.newPassword1}
                  onChange={this.chvalPassword1}
                  placeholder='New Password'
                  disabled={this.props.disable}
                  style={(this.state.newPasswordErr || this.state.newPasswordCmplxErr) ? errorStyle: restingStyle}
                />
                <div className="password-strength-indicator">
                  {this.renderPasswordStrength(this.state.newPassword1)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='form__form-group'>
          <label className='form__form-group-label'>Verify New Password</label>
          <div className='form__form-group-field'>
            <div className='form__form-group-icon'>
              <KeyVariantIcon />
            </div>
            <div className='form__form-group-input-wrap'>
              <div style={{...restingStyle, display:"flex", position:"relative"}}>
                <input
                  name='new_password2'
                  type='password'
                  value={this.state.newPassword2}
                  onChange={this.chvalPassword2}
                  placeholder='Verify Password'
                  disabled={this.props.disable}
                  style={(this.state.newPasswordErr || this.state.newPasswordCmplxErr) ? errorStyle: restingStyle}
                />
                <div className="password-strength-indicator">
                  {this.renderPasswordStrength(this.state.newPassword2)}
                </div>
              </div>
              {this.state.newPasswordErr      ? <span className='form__form-group-error'>Please enter new passwords that match eachother, but not your old password</span> : null}
              {this.state.newPasswordCmplxErr ? <span className='form__form-group-error'>Your password is too easy to guess. Use a mix of numbers and letters, and do not include the words <i>password</i>, <i>pass</i> etc.</span> : null}
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }

  public render(): JSX.Element {

    return(
      <div className="dashboard">
        <form className='form form--horizontal'>

          {this.renderPasswdControls()}

          <div className='form__form-group' style={{justifyContent: "center"}}>
            <ButtonToolbar className='modal__footer' style={{marginTop: "20px"}}>
              {
                null !== this.props.toggleModal
                ?
                  <Button
                    size='sm'
                    type='button'
                    onClick={this.props.toggleModal}
                    disabled={this.props.disable}>
                    Cancel
                  </Button>
                :
                  null
              }
              <Button
                color='primary'
                outline={(null !== this.props.toggleModal) ? true : false}
                style={(null !== this.props.toggleModal) ? {borderColor:"white",color:"white"} : {}}
                type='button'
                size='sm'
                onClick={this.submitForm}
                disabled={this.props.disable}>
                Change
              </Button>
              {
                this.props.disable
                ?
                  <Spinner name="double-bounce" color={(null !== this.props.toggleModal) ? Consts.ADDUSR_INDICATOR_COLOR : Consts.LOGGING_IN_INDICATOR_COLOR} />
                :
                  <span style={{width:"27px"}} />
              }
            </ButtonToolbar>
            </div>
        </form>
      </div>
    );
  }
}
