import React                          from "react";
import Router                         from "./Router";
import { QueryClient,
         QueryClientProvider }        from "react-query";
import { AppProvider }                from "../context_providers/AppProvider";
import { AuthProvider }               from "../context_providers/AuthProvider";
import { DataProvider }               from "../context_providers/DataProvider";
import { ConfirmProvider }            from "../context_providers/ConfirmProvider";

import Notif                          from "../shared/Notif";
import SecurityLockIcon               from "mdi-react/SecurityLockIcon";
import AlertIcon                      from "mdi-react/AlertIcon";
import BombIcon                       from "mdi-react/BombIcon";


const App: React.FC<{}> = (props): JSX.Element => {

  const queryClient = React.useMemo(() => new QueryClient(), []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppProvider>
          <DataProvider>
            <ConfirmProvider>
              <div>
                {/* {!loaded && <div className={`load${this.state.loading ? "" : " loaded"}`}>
                  <div className='load__icon-wrap'>
                    <svg className='load__icon'>
                      <path fill={Consts.CHRT_COLOR_1} d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'/>
                    </svg>
                  </div>
                </div>} */}
                <div>
                  <Router/>
                </div>
              </div>
            </ConfirmProvider>
          </DataProvider>
        </AppProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}

//
// Helpers
//

// TODO move this into the Notif service
export const toastToFailedLoadData = (httpStatus: number) => {

  switch (httpStatus) {
    case 403: // this is what we are expecting
    case 401: // 401 is not likely....
      Notif.notifWarn(<div><SecurityLockIcon color='#FFF'/><br/>You do not have permission to do this</div>);
      break;
    case 409:
      Notif.notifWarn(<div><BombIcon color='#FFF'/> Record already exists.<br/>Please try again.</div>);
      break;
    case 501:
      Notif.notifWarn(<div><BombIcon color='#FFF'/> Looks like someone forgot to implement that.<br/>We'll get right on that.</div>);
      break;
    case 1000:
      Notif.notifError(<div><BombIcon color='#FFF'/> No response from the server<br/>Please try again</div>);
      break;
    case 1001:
      Notif.notifError(<div><BombIcon color='#FFF'/> Sorry, but a non-recoverable error occurred<br/>Please try again later</div>);
      break;
    case 1002:
      Notif.notifError(<div><BombIcon color='#FFF'/> No data was returned from the server<br/>Please try again</div>);
      break;
    case 1003:
      Notif.notifError(<div><BombIcon color='#FFF'/> An unexpected error occurred whilst trying to modify this campaign</div>);
      break;
    default:
      Notif.notifError(<div><AlertIcon color='#FFF'/> Sorry, but an unexpected error has occurred<br/>Please try again later</div>);
      break;
  }
}

export default App;
