import * as React               from 'react';
import Transition               from 'react-transition-group/Transition';
import Consts                   from '../../shared/Consts';

interface IComponentTransitionProps {
  in           : boolean;      // true - visible, false - hide
  unmountOnExit: boolean;
}

/**
 * Simple wrapper for the react-transition-group.
 * this.props.in specifies if the component is visible
 * otherwise I have specified the CSS transition in Consts.defaultTransStyle (just a simple opacity ease in/out)
 **/
const ComponentTransition: React.FC<IComponentTransitionProps> = (props): JSX.Element => {

  return (
    <Transition in={props.in} timeout={Consts.transDuration} appear={true} exit={false} enter={true} unmountOnExit={props.unmountOnExit}>
      {(transSte: any) => (
        <div style={{...Consts.defaultTransStyle, ...Consts.transitionStyles[transSte], width:'100%'}}>
          {props.children}
        </div>
      )}
    </Transition>
  );
}

export default ComponentTransition;
