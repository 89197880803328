import * as React                       from "react";
import {  Button,
          ButtonToolbar,
          Modal }                       from "reactstrap";
import AccountPlusIcon                  from "mdi-react/AccountPlusIcon";
import { MdiReactIconComponentType }    from "mdi-react";
import cn from "clsx";


export interface IModalWrapperProps {
  title         : string;
  hideBtn       : boolean;
  color         : "primary" | "success" | "warning" | "danger";
  colored       : boolean;
  header        : boolean;
  icon?         : MdiReactIconComponentType;
  cancelButtons : boolean;
  disabled      : boolean;
  isOpen        : boolean;
  large?        : boolean;
  toggleModal   : () => void;

  hideIcon?     : boolean;
  hideCloseBtn? : boolean;
  borderRadius? : boolean;
  transparentBg?: boolean;
  noBackdrop?   : boolean;
}

export default class ModalWrapper extends React.Component<IModalWrapperProps, {}> {

  constructor(props: IModalWrapperProps){
      super(props);
  }

  componentDidUpdate(prevProps: IModalWrapperProps/*, prevState: IModalWrapperState*/) {
    if (prevProps.disabled !== this.props.disabled && false === this.props.disabled) {
      this.setState( {isOpen: false} );
    }
  }

  private toggle = () => {

    if (!(this.props.disabled)) {
      this.props.toggleModal()
    }
  }

  //
  // Rendering
  //

  private renderIcon = (): JSX.Element => {

    if (this.props.hideIcon === true) {
      return (<></>);
    }

    if (undefined !== (this.props?.icon ?? undefined)) {
      const Icon = this.props.icon as any;
      return (
        // TODO: fix the coloring here
        this.props.colored ? <Icon color="#ffffff" /> : <Icon color="#70bbfd"/>
      );
    } else {
      let Icon;

      switch (this.props.color) {
        case "primary":
          Icon = <span className="lnr lnr-pushpin modal__title-icon"/>;
          break;
        case "success":
          Icon = <span className="lnr lnr-thumbs-up modal__title-icon"/>;
          break;
        case "warning":
          Icon = <span className="lnr lnr-flag modal__title-icon"/>;
          break;
        case "danger":
          Icon = <span className="lnr lnr-cross-circle modal__title-icon"/>;
          break;
        default:
          Icon = <span className="lnr lnr-pushpin modal__title-icon"/>;
          break;
      }

      return (Icon);
    }
  }

  public render(): JSX.Element {

    return (
      <>

        {/*  TODO: give the caller the ability to override/customize this with their own button supplied as some text or something */}
        {
          this.props.hideBtn
          ?
            null
          :
          <div className="add_user_btn_container">
            <Button color="primary" size="sm" onClick={this.toggle} disabled={this.props.disabled}>
              <AccountPlusIcon />
            </Button>
          </div>
        }


        <Modal isOpen={this.props.isOpen}
               toggle={this.toggle}
               backdrop={this.props.noBackdrop ? false : true}
               style={{
                //  borderRadius: this.props.borderRadius ? "30px" : "0px",
                 maxWidth: this.props.large ? "950px" : undefined,
                 pointerEvents: "none",
               }}
          className={cn(
            `modal-dialog--${this.props.color} ${this.props.colored ? "modal-dialog--colored" : ""} ${this.props.header ? "modal-dialog--header" : ""}`,
            {"modal-content--transparent": this.props.transparentBg === true},
          )}
          >
          <div className="modal__header">
            {this.props.hideCloseBtn === true ? "" : <span className="lnr lnr-cross modal__close-btn" onClick={this.toggle}/>}
            {/* <span className="lnr lnr-cross modal__close-btn" onClick={this.toggle}/> */}
            {this.props.header ? "" : this.renderIcon()}
            <h4 className="bold-text modal__title">{this.props.title}</h4>
          </div>


          <div className="modal__body theme-light">
            {this.props.children}
          </div>

          {this.props.cancelButtons
            ?
              <ButtonToolbar className="modal__footer">
                <Button onClick={this.toggle} disabled={this.props.disabled}>Cancel</Button>{" "}
                <Button outline={this.props.colored} color={this.props.color} onClick={this.toggle} disabled={this.props.disabled}>Ok</Button>
              </ButtonToolbar>
            :
              null
          }
        </Modal>
      </>
    );
  }
}
