import React                        from "react";
import Dropdown                     from "../../../../components/dropdown/Dropdown";
import { DropdownToggle }           from "reactstrap";
import TopbarCampaignSelectorBtn    from "./TopbarCampaignSelectorBtn";
import Scrollbar                    from "react-smooth-scrollbar"
import Consts                       from "../../../../shared/Consts";
import Spinner                      from "react-spinkit";
import { DataCtx,
         DataProviderCtx }          from "../../../../context_providers/DataProvider";


interface TopbarCampaignSelectorProps {
  isLoading : boolean;
}

const TopbarCampaignSelector: React.FC<TopbarCampaignSelectorProps> = ({isLoading}): JSX.Element => {

  const dataCtxValue  = React.useContext<DataProviderCtx>(DataCtx);

  React.useEffect( () => {
    dataCtxValue.fnRefreshSummary!();
  }, []);

  //
  // rendering

  const renderLoadingIndicator = (): JSX.Element | null => {
    return(
      isLoading
      ?
        <div className="topbar__select__loading">
          <Spinner name="double-bounce" color={Consts.CAMPAIGN_LST_LDNG_INDCTR_COLOR} />
        </div>
      :
        null
    );
  }

  const renderDropdownContent = (): JSX.Element =>
    <Scrollbar className="campaignselect__scroll scroll"
               continuousScrolling={false}
               plugins={{
                 disableScroll: { direction: "x" },
               }}>
      <div className="topbarcampaignselector_content">

        date picker will go here
      </div>
    </Scrollbar>

// TODO....sort this out....
  return(
    <></>
    // <div className="topbarcampaignselector_wrapper">
    //   <Dropdown
    //     content={renderDropdownContent()}
    //     arrow={true}
    //   >
    //     <DropdownToggle
    //       tag="button"
    //       className="campaign-selector-toggle-btn"
    //       disabled={isLoading}
    //     >
    //       <TopbarCampaignSelectorBtn
    //          title="2021-01-01 to 2021-03-01"
    //       />
    //     </DropdownToggle>
    //   </Dropdown>
    // </div>
  );
}

export default (TopbarCampaignSelector);
