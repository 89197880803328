import {
  Api,
  RequestEvent,
  RequestMiddleware,
  RequestMethod,
  AxiosRequestBackend,
  setRequestBackend,
}                                 from "api-def";
import axios                      from "axios";
import Consts                     from "../shared/Consts";
import { InsDemoRawEventRequest } from "../shared/interfaces_api_admin";

//
// one-time set-up

setRequestBackend(new AxiosRequestBackend(axios));

//
// middleware

export const appMiddleware: RequestMiddleware = {
  [RequestEvent.BEFORE_SEND]: async (context) => {

    const bearerToken = localStorage.getItem(Consts.LOCALSTORE_BEARER_TOKEN);
    const authHeader  = bearerToken ? `Bearer ${bearerToken}` : "";

    context.updateHeaders({
      "Content-Type": "application/json",
      Authorization : authHeader,
    });
  },
};

//
// config

const apiDef = new Api({
  baseUrl    : `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/v1`,
  name       : "App Backend",
  config     : { retry: 3 },
  mocking    : { enabled: Consts.USE_MOCK_DATA },
  middleware : [appMiddleware],
});

//
// endpoints (star)

export const addNewDemoSale = apiDef.endpoint()
  .responseOf<{
    success : boolean;
    message?: string;
  }>()
  .bodyOf<InsDemoRawEventRequest>()
  .build({
    id: "demo_add",
    path: "/demo/new",
    method: RequestMethod.POST,
    description: "Add a new demo raw event",
    responseType: "json",
    config: {
      acceptableStatus: [200, 202],
    },
    mocking: {
      delay: [1000, 3000],
      handler: ({ body, params, query }, res) => {
        return (res.status(200).send({
          success: true,
        }));
      },
    },
  })

export const deleteSale = apiDef.endpoint()
  .responseOf<{
    success : boolean;
    message?: string;
  }>()
  .bodyOf<{saleId: number}>()
  .build({
    id: "del_sale",
    path: "/admin/delsale",
    method: RequestMethod.DELETE,
    description: "Delete sale",
    responseType: "json",
    config: {
      acceptableStatus: [200, 202],
    },
    mocking: {
      delay: [1000, 3000],
      handler: ({ body, params, query }, res) => {
        return (res.status(200).send({
          success: true,
        }));
      },
    },
  })

export const rstPassword = apiDef.endpoint()
  .responseOf<{
    success : boolean;
    message?: string;
  }>()
  .bodyOf<{email: string}>()
  .build({
    id: "rst_pass",
    path: "/user/rstpass",
    method: RequestMethod.POST,
    description: "Reset password",
    responseType: "json",
    config: {
      acceptableStatus: [200, 202],
    },
    mocking: {
      delay: [1000, 3000],
      handler: ({ body, params, query }, res) => {
        return (res.status(200).send({
          success: true,
        }));
      },
    },
  })

export const usrGetNotif = apiDef.endpoint()
  .responseOf<{
    success         : boolean;
    emailSalesReport: boolean;
    emailUsageStats?: boolean;
  }>()
  .build({
    id: "get_notif",
    path: "/user/getnotif",
    method: RequestMethod.GET,
    description: "Gets the notif subscriptions for a user",
    responseType: "json",
    config: {
      acceptableStatus: [200, 202],
    },
    mocking: {
      delay: [1000, 3000],
      handler: ({ body, params, query }, res) => {
        return (res.status(200).send({
          success: true,
          emailSalesReport: false,
        }));
      },
    },
  })

export interface SetNotificationPrefReq {
  reportName: "emailUsageStats" | "emailSalesReport";
  enabled   : boolean;
}
export const usrSetNotif = apiDef.endpoint()
  .responseOf<{
    success : boolean;
    message?: string;
  }>()
  .bodyOf<SetNotificationPrefReq>()
  .build({
    id: "set_notif",
    path: "/user/setnotif",
    method: RequestMethod.POST,
    description: "Sets the notif subscriptions for a user",
    responseType: "json",
    config: {
      acceptableStatus: [200, 202],
    },
    mocking: {
      delay: [1000, 3000],
      handler: ({ body, params, query }, res) => {
        return (res.status(200).send({
          success: true,
        }));
      },
    },
  })

export const getCompanyMappings = apiDef.endpoint()
  .responseOf<{
    success : boolean;
    message?: string;
    companyMappings?: {
      inputPattern        : string;
      mapsToCompanyId     : number;
      mapsToCompanyName   : string;
      mapsToCompanyLocDesc: string;
    }[];
  }>()
  .build({
    id: "get_map",
    path: "/admin/mappings",
    method: RequestMethod.GET,
    description: "GET Company Mappings",
    responseType: "json",
    config: {
      acceptableStatus: [200],
    },
    mocking: {
      delay: [1000, 3000],
      handler: ({ body, params, query }, res) => {
        return (res.status(200).send({
          success: true,
          companyMappings: [],
        }));
      },
    },
  })
