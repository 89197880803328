import * as React       from "react";
import Consts           from "../../../shared/Consts";
import cn               from "clsx";

export const LoginLogo: React.FC<{}> = (props): JSX.Element => {

  return (
    <>
      <div className={cn("login__logo", {demo: Consts.USE_DEMO_BRAND})} />
      {/*
        <h3 className='account__title'>Welcome to&nbsp;
          <span className='account__logo'>Stat
            <span className='account__logo-accent'>Camp</span>
          </span>
        </h3>
      */}
      {Consts.USE_DEMO_BRAND && <h4 className="account__subhead subhead">Demo System</h4>}
      {(!Consts.IS_PROD) && <h4 className="account__subhead subhead">(Staging)</h4>}
    </>
  );
}
