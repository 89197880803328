import * as React               from "react";
import EmailOutlineIcon         from 'mdi-react/EmailOutlineIcon';
import Consts                   from "../../../shared/Consts";
import Notif                    from "../../../shared/Notif";
import { Button,
         ButtonToolbar }        from 'reactstrap';
import Spinner                  from 'react-spinkit';

interface IRstPasswordFormProps {
  disable     : boolean;
  submitForm  : (email: string) => void;
  toggleModal : null | ( () => void );
}

interface IRstPasswordFormState {
  enteredEmail   : string;
  enteredEmailErr: boolean
}

export default class RstPasswordForm extends React.Component<IRstPasswordFormProps, IRstPasswordFormState> {

  readonly state: IRstPasswordFormState  = {
    enteredEmail: '',
    enteredEmailErr: false
  };

  constructor(props: IRstPasswordFormProps) {
    super(props);
  }

  //
  // Form Control
  //

  private chvalEnteredEmail = (event: any) => {

    this.setState({
      enteredEmail   : event.target.value,
      enteredEmailErr: false
    });
  }

  //
  // Form Submission
  //

  private handleEnterKeyPress = (event: any) => {

    let keycode = event.keyCode || event.which;
    if (13 === keycode) {
      event.stopPropagation();
      event.preventDefault();
      if ( !(this.props.disable || !(this.state.enteredEmail.trim())) ) {
        this.submitForm();
      }
    }
  }

  private submitForm = () => {

    this.setState({
      enteredEmailErr: false
    }, this.submitForm_)
  }

  private submitForm_ = () => {

    if ( this.validateForm() ) {

      this.props.submitForm( this.state.enteredEmail.trim().toLowerCase() );

    } else {
      Notif.notifRainbow('Please enter a valid email address');
    }
  }

  private validateForm = (): boolean => {

    let bRet = true;

    let email = this.state.enteredEmail.trim().toLowerCase();
    let re    = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( !(re.test(email)) ) {
      bRet = false;
      this.setState({
        enteredEmailErr: true
      })
    }

    return bRet;
  }

  //
  // Rendering
  //

  private renderFormControls = (): JSX.Element => {

    const errorStyle           = {...Consts.inputErrorStyle, background: 'white'}
    const restingStyle         = {background: 'white'}

    return(
      <div className='form__form-group'>
        <label className='form__form-group-label'>Email</label>
        <div className='form__form-group-field'>
          <div className='form__form-group-icon'>
            <EmailOutlineIcon/>
          </div>
          <input
            name='emailaddr'
            type='email'
            value={this.state.enteredEmail}
            onChange={this.chvalEnteredEmail}
            placeholder='Your Email Address'
            disabled={this.props.disable}
            style={this.state.enteredEmailErr ? errorStyle : restingStyle}
            onKeyPress={this.handleEnterKeyPress}
          />
        </div>
      </div>
    );
  }

  public render(): JSX.Element {

    return(
      <div className="dashboard">
        <p>
          Use this form to reset your password when you have forgotten it
        </p>
        <br/>
        <form className='form form--horizontal'>

          {this.renderFormControls()}

          <div className='form__form-group' style={{justifyContent: 'center'}}>
            <ButtonToolbar className='modal__footer' style={{marginTop: '20px'}}>
              {
                null !== this.props.toggleModal
                ?
                  <Button
                    size='sm'
                    type='button'
                    onClick={this.props.toggleModal}
                    disabled={this.props.disable}>
                    Cancel
                  </Button>
                :
                  null
              }
              <Button
                color='primary'
                outline={(null !== this.props.toggleModal) ? true : false}
                style={(null !== this.props.toggleModal) ? {borderColor:'white',color:'white'} : {}}
                type='button'
                size='sm'
                onClick={this.submitForm}
                disabled={this.props.disable}>
                Submit
              </Button>
              {
                this.props.disable
                ?
                  <Spinner name="double-bounce" color={(null !== this.props.toggleModal) ? Consts.ADDUSR_INDICATOR_COLOR : Consts.LOGGING_IN_INDICATOR_COLOR} />
                :
                  <span style={{width:'27px'}} />
              }
            </ButtonToolbar>
            </div>
        </form>
      </div>
    );
  }
}
