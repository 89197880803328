import React                   from "react";
import { AuthProviderCtx,
         GAuthCtx }            from "../context_providers/AuthProvider";

export const useSessionNumber = (): number => {

  const authCtxValue = React.useContext<AuthProviderCtx>(GAuthCtx);

  const sessionNumber = authCtxValue.fnGetSessionNumber!();

  return(sessionNumber);
}


export const useIsUsrAdmin = (): boolean => {

  const authCtxValue = React.useContext<AuthProviderCtx>(GAuthCtx);

  const isUsrAdmin = authCtxValue.isAdmin!();

  return(isUsrAdmin);
}
