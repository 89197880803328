export default class Consts {

  private static readonly envVars = (window as any)._env_ || {} as any; // see env.sh and public/env-config.js

  //
  // APIs and External Sources
  public static readonly USE_MOCK_DATA            : boolean = false;
  public static readonly ALLOW_ADMIN_FRCE_REFRESH : boolean = true;

  public static readonly IS_PROD                  : boolean = undefined === Consts.envVars?.REACT_APP_IS_PROD ? ("true" === process.env.REACT_APP_IS_PROD) : ("true" === Consts.envVars.REACT_APP_IS_PROD);

  public static readonly BACKEND_HOST             : string  = Consts.envVars.REACT_APP_BACKEND_HOST  || (process.env.REACT_APP_BACKEND_HOST as string);
  public static readonly BACKEND_PORT             : string  = Consts.envVars.REACT_APP_BACKEND_PORT  || (process.env.REACT_APP_BACKEND_PORT  as string);
  public static readonly BACKEND_PROTO            : string  = Consts.envVars.REACT_APP_BACKEND_PROTO || (process.env.REACT_APP_BACKEND_PROTO as string);

  public static readonly DEMO_ACCOUNT_FORMAT      : RegExp  = /^demo[a-z0-9\-_]*@autocomms.co$/i;
  public static readonly ND_ACCOUNT_FORMAT        : RegExp  = /^.+@newday.co.uk$/i;
  public static readonly ND_DEMO_ACCOUNT_FORMAT   : RegExp  = /^.+_newday@autocomms.co$/i;
  public static readonly CAMPAIGN_FORMAT_LOOSE    : RegExp  = /.+(?<quarter>Q\d{1}|Issue\d)\s(?<year>20\d{2})\s.*(?<type>FULL|NON) */; // There might be trailing space at the back (although there shouldn't be)
  public static readonly CAMPAIGN_FORMAT_TIGHT    : RegExp  = /^(?<company>\w+) (?<sitegroup>\w+) (?<sitename>\w+) (?<quarter>Q\d{1}|Issue\d) (?<year>20\d{2}) (\([\w -]+\) )?(?<type>FULL|NON)$/;

  public static readonly HUBSITE_SECRET           : string   = "djdfk33wemnsio937232ndsf984jkbsdfojmns9osdfs5dfsdf";
  public static readonly HASH_SUPERADMINS         : string[] = ["0b47250d20e0311cecf78f0021076562"]; // using md5 ["d...sper...@imm"]

  //
  // Routing
  public static readonly ROUTE_DEFAULT            : string  = "/ac/dashboard";

  //
  // Google APIs (Google just uses one credential, and you enable various APIs for it)
  public static readonly GOOGLE_API_KEY           : string                    = Consts.envVars.REACT_APP_GOOGLE_API_KEY || (process.env.REACT_APP_GOOGLE_API_KEY as string);
  public static readonly MAP_DEFAULT_CENTER       : {lat: number, lng:number} = {lat: 54.5074, lng: -0.1278}; // London: 51.5074° N, 0.1278° W
  public static readonly MAP_DEFAULT_ZOOM         : number                    = 5;        // 1-world, 5-landmass/continent, 10-city, 15-streets
  public static readonly MAP_DEFAULT_LANG         : string                    = "en-GB";  // https://developers.google.com/maps/documentation/javascript/localization#Language
  public static readonly MAP_DEFAULT_REGION       : string                    = "GB";     // https://developers.google.com/maps/documentation/javascript/localization#Region
  public static readonly MAP_OPEN_MARKER_URL      : string                    = "/img/place-marker-1.png";
  public static readonly MAP_CLICK_MARKER_URL     : string                    = "/img/place-marker-2.png";
  public static readonly MAP_CLKANDOPN_MARKER_URL : string                    = "/img/place-marker-3.png";
  public static readonly MAP_CLUSTER_MARKER_URL   : string                    = "/img/clust/m";             // this should contain m(1..5).png

  //
  // General App Settings
  public static readonly ENABLE_DEMO_SCR            : boolean = undefined === Consts.envVars?.REACT_APP_ENABLE_DEMO_SCR ? ("true" === process.env.REACT_APP_ENABLE_DEMO_SCR) : ("true" === Consts.envVars.REACT_APP_ENABLE_DEMO_SCR);
  public static readonly USE_DEMO_BRAND             : boolean = undefined === Consts.envVars?.REACT_APP_USE_DEMO_BRAND  ? ("true" === process.env.REACT_APP_USE_DEMO_BRAND ) : ("true" === Consts.envVars.REACT_APP_USE_DEMO_BRAND);
  public static readonly AUTO_SELECT_FRST_CAMPAIGN  : boolean = true;   // when the list of campaigns is returned from the server, we should auto-select the first one
  public static readonly MAINDASH_NUM_CARDS         : boolean = true;   // Do we do the sent, opens, clicks cards on the main dashboard?
  public static readonly INFO_POPOVER_TIMEOUT_MS    : number  = 10000;  // Used for the feedback and campaign selector popovers
  public static readonly SCREEN_WIDTHPX_HIDE_SIDEBAR: number  = 576;

  //
  // Local Storage Items
  public static readonly LOCALSTORE_THEME         : string  = "app_theme";
  public static readonly LOCALSTORE_CAMP_LAST_SELE: string  = "data_lastselected_camp_id";
  public static readonly LOCALSTORE_RST_PASS      : string  = "ischp_req";
  public static readonly LOCALSTORE_BEARER_TOKEN  : string  = "bearer_token";
  public static readonly LOCALSTORE_SESSION_COUNT : string  = "sssnct";

  //
  // Styling
  public static readonly CHRT_CIRCLE_THICKNESS_PX         : number  = 14;
  public static readonly CHRT_CIRCLE_THICKNESS_PX_MOB     : number  = 8;
  public static readonly CHRT_CIRCLE_BIG_INNER_RAD_PX     : number  = 74; // inner radius for big circles (pie charts)
  public static readonly CHRT_CIRCLE_BIG_INNER_RAD_PX_MOB : number  = 59; // inner radius for big circles (pie charts) on small mobile screens
  public static readonly CHRT_CIRCLE_SM_INNER_RAD_PX      : number  = 45; // inner radius for small circles (pie charts)
  public static readonly CHRT_NESTED_PIE_THICKNESS_PX     : number  = 10; // this is the "campaign engagement" pie chart on the campaign dash
  public static readonly CHRT_NESTED_PIE_INNER_RAD_PX     : number  = 72;
  public static readonly CHRT_NESTED_PIE_RINGSPACING_PX   : number  =  4;

  public static readonly MAP_LOADING_INDICATOR_COLOR      : string  = "#70bbfd";
  public static readonly RPT_LOADING_INDICATOR_COLOR      : string  = "#70bbfd";
  public static readonly LOGGING_IN_INDICATOR_COLOR       : string  = "#70bbfd";
  public static readonly CAMPAIGN_LST_LDNG_INDCTR_COLOR   : string  = "#70bbfd";
  public static readonly ADMNLST_USRS_LDNG_INDCTR_COLOR   : string  = "#70bbfd";
  public static readonly ADDUSR_INDICATOR_COLOR           : string  = "#ffffff";
  public static readonly CHRT_COLOR_1                     : string  = "#018e42"; // orig - '#4ce1b6';  // we also use this for the little circle loading indicator, and the colorAccent (in the scss)
  public static readonly CHRT_COLOR_2                     : string  = "#008dd5"; // orig - '#6faae1';
  public static readonly CHRT_COLOR_3                     : string  = "#c39fdf";
  public static readonly CHRT_COLOR_4                     : string  = "#f198ca";
  public static readonly CHRT_COLOR_5                     : string  = "#f7d002"; // orig - '#f6da6e';  // also $color-yellow in the scss
  public static readonly COLOR_DANGER                     : string  = "#e63946"; // orig - '#ff4861';  // also $color-red in the scss
  public static readonly COLOR_WARNING                    : string  = "#f7d002"; // orig - '#f6da6e';  // also $color-yellow in the scss
  public static readonly COLOR_TEXT_LIGHT_THEME           : string  = "#555555";
  public static readonly COLOR_TEXT_DARK_THEME            : string  = "#999999";
  public static readonly COLOR_TEXT_ERROR                 : string  = "#ad4444";

  public static readonly inputErrorStyle                            = {borderColor: "#fa4a86"};

  //
  // Instructional Popovers - TODO we need a better style for
  public static readonly POSHOWEVERY_FEEDBACK_PROMPT      : number[] = [];

  //
  // Transitions
  public static readonly rechartsAnimationDelayMs      : number  = 400;
  public static readonly rechartsAnimationLongDelayMs  : number  = 800;
  public static readonly transDuration                 : number  = 200;

  public static readonly defaultTransStyle             : any     = {
                          transition: `opacity ${Consts.transDuration}ms ease-in-out`,
                          opacity: 0,
                        }

  public static readonly transitionStyles              : any     = {
                          entering: { opacity: 0 },
                          entered:  { opacity: 1 },
                          exiting:  { opacity: 1 },
                          exited:   { opacity: 0 },
                        };

  //
  // form validation
  public static readonly emailRegex                    : RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //
  // dates
  public static readonly dateFormatForDisplay          : string = "dd/MM/yyyy";

}
