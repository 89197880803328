import * as React               from "react";
import ChPasswdForm             from "../../components/form_usr/ChPasswdForm";
import { IChangePasswdRequest } from "../../shared/interfaces";
import { usrChPassword }        from "../../services/BackendApi";
import AlertIcon                from "mdi-react/AlertIcon";
import Notif                    from "../../shared/Notif";
import Consts from "../../shared/Consts";

interface IChPassForcedInnerProps {
  currUserName            : string;
  fnRstPassRstBeingForced : () => void;
}

interface IChPassForcedInnerState {
  submittingChPassReq: boolean;
  chPasswdReq          : IChangePasswdRequest | null;
}

export default class ChPassForcedInner extends React.Component<IChPassForcedInnerProps, IChPassForcedInnerState> {

  readonly state: IChPassForcedInnerState = {
    submittingChPassReq: false,
    chPasswdReq        : null,
  };

  constructor(props: IChPassForcedInnerProps){
    super(props);
  }

  //
  // Form Submission
  //

  private chUsrPassword = (reqBody: IChangePasswdRequest) => {

    this.setState({
      submittingChPassReq  : true,
      chPasswdReq          : reqBody,
    }, this.chUsrPassword_);
  }

  private chUsrPassword_ = async () => {

    if (this.state.chPasswdReq) {

      const response = await usrChPassword(this.state.chPasswdReq.oldpassword, this.state.chPasswdReq.newpassword);
      if (response.success) {
        this.setState({
          submittingChPassReq  : false,
          chPasswdReq          : null,
        }, this.afterSucessfulPasswordRst);
      } else {
        const errMsg = response.data?.message || "An unexpected error occurred";
        Notif.notifError(<div><AlertIcon />{errMsg}</div>);
        this.setState({
          submittingChPassReq: false,
          chPasswdReq        : null,
        });
      }
    }
  }

  private afterSucessfulPasswordRst = () => {

    Notif.notifInfo("Your password has been changed");
    this.props.fnRstPassRstBeingForced();
    window.location.href = Consts.ROUTE_DEFAULT;
  }

  //
  // Rendering
  //

  public render(): JSX.Element {
    return(
      <ChPasswdForm
        currUserName={this.props.currUserName}
        disable={this.state.submittingChPassReq}
        submitForm={this.chUsrPassword}
        toggleModal={null}
      />
    );
  }
}
