import { toast, ToastOptions }        from "react-toastify";


type notifType = "rainbow" | "info" | "warn" | "error" | "success";
/**
 * For this to work, just make sure you have <ToastContainer /> in your DOM somwhere
 **/
export default class Notif {

  private static notif = (type: notifType) => (msg: string | JSX.Element, autoClose?: false | number) => {

    const options: ToastOptions = {position : toast.POSITION.TOP_RIGHT, autoClose: autoClose ?? 5000};
    switch(type) {
      case "info":
        toast.info(msg, options);
        break;
      case "success":
        toast.success(msg, options);
        break;
      case "warn":
        toast.warn(msg, options);
        break;
      case "error":
        toast.error(msg, options);
        break;
      case "rainbow":
      default:
        toast(msg, options);
        break;
    }
  }

  public static notifRainbow = Notif.notif("rainbow");
  public static notifInfo    = Notif.notif("info");
  public static notifWarn    = Notif.notif("warn");
  public static notifError   = Notif.notif("error");
  public static notifSuccess = Notif.notif("success");
}
