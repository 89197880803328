import * as React               from 'react';
import Topbar                   from './topbar/Topbar';
import Sidebar                  from './sidebar/Sidebar';
import { ToastContainer }       from 'react-toastify';
// context providers
import { GAppCtx }              from '../../context_providers/AppProvider';
import { GAuthCtx,
         UserNameDefault }      from '../../context_providers/AuthProvider';

// NOTE: everything here and down extends React.Component, because extending a React.PureComponent
//       would block the updates to components underneeth, which means when we eventually get to
//       the <NavLink />s, the activeClassName attribute will never be updated when we change the route
export default class Layout extends React.Component<{},{}> {

  private ctxErr = () => {
    console.error('[EE] Sorry, but that ContextAPI function is undefined');
  }

  public render(): JSX.Element {
    return (
      <GAuthCtx.Consumer>
        {(ctxAuth) => (
          <GAppCtx.Consumer>
            {(ctx) => (
              <div>
                <ToastContainer />
                <Topbar
                  currUserName={ctxAuth.fnGetUsrName ? ctxAuth.fnGetUsrName() : UserNameDefault}
                  fnChSidebarVisibility={ctx.fnChSidebarVisibility || this.ctxErr}
                  fnChSidebarVisibilityMobile={ctx.fnChSidebarVisibilityMobile || this.ctxErr}
                />
                <Sidebar
                  sidebarState={ctx.sidebarState}
                  fnChSidebarVisibilityMobile={ctx.fnChSidebarVisibilityMobile || this.ctxErr}
                  fnChThemeDark={ctx.fnChThemeDark   || this.ctxErr}
                  fnChThemeLight={ctx.fnChThemeLight || this.ctxErr}
                  fnChThemeCrisp={ctx.fnChThemeCrisp || this.ctxErr}
                  fnSignOutOfSession={ctxAuth.fnSignOutOfSession || this.ctxErr}
                  fnShowAdminFeatures={ctxAuth.isAdmin!}
                />
              </div>
            )}
          </GAppCtx.Consumer>
        )}
      </GAuthCtx.Consumer>
    );
  }
}
