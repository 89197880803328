import React                                from "react";
import { Route, Switch }                    from "react-router-dom";
import Layout                               from "../pages/_layout/Layout";
import MainWrapper                          from "./MainWrapper";
import { PrivateRoute }                     from "./PrivateRoute";
import { PrivateAdminRoute }                from "./PrivateAdminRoute";
import { ChPassRoute }                      from "./ChPassRoute";
import { CenteredLoadingIndicator }         from "../components/loading/CenteredLoadingIndicator";
import Consts                               from "../shared/Consts";

import LogIn                                from "../pages/log_in/LogIn";
import Callback                             from "../pages/callback/Callback";
import ChPassForced                         from "../pages/ch_pass_forced/ChPassForced";
//import ExamplePageOne                     from '../pages/example/ExamplePageOne';
//import ExamplePageTwo                     from '../pages/example_two/ExamplePageTwo';

const AutocommsDashboard      = React.lazy( () => import("../pages/dashboards/autocomms/AutocommsDashboard")        );
const AutocommsAnalytics      = React.lazy( () => import("../pages/analytics/AutocommsAnalytics")                   );
const CustomerDetailScr       = React.lazy( () => import("../pages/customer-detail/CustomerDetailScr")              );
const AdminUsers              = React.lazy( () => import("../pages/admin/users/Users")                              );
const AdminCompanies          = React.lazy( () => import("../pages/admin/companies/CompaniesScr")                   );
const AdminTasks              = React.lazy( () => import("../pages/admin/tasks/TasksScr")                           );
const AdminUsageEvents        = React.lazy( () => import("../pages/admin/log-usage-events/UsageEventsScr")          );
const AdminAuditEvents        = React.lazy( () => import("../pages/admin/log-audit/AuditEventsScr")                 );
const AdminVersionHist        = React.lazy( () => import("../pages/admin/timeline/VersionsHist")                    );
const AdminAppSettingsScr     = React.lazy( () => import("../pages/admin/app-settings/AppSettingsScr")              );
const AdminDemoNewScr         = React.lazy( () => import("../pages/admin/demo-new/DemoNewScr")                      );
const AdminContactScheduleScr = React.lazy( () => import("../pages/contact-schedule/ContactScheduleScr")            );

// TODO - nicer 404 error
// If they haven't authorized yet, we'll just bounce them back to the login
const page404 = (): JSX.Element | null => {
  return(
    <div>Sorry, but that pages does not exist</div>
  );
}

/**
 * <Switch> renders the first child <Route> or <Redirect> that matches the location
 **/
const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route       exact={true}  path='/'           component={Callback}/>
        <Route       exact={true}  path='/index.html' component={Callback}/>
        <Route       exact={true}  path='/log_in'     component={LogIn}/>
        <ChPassRoute exact={true}  path='/rst_pass'   component={ChPassForced}/>
        <Route       exact={false} path='/callback'   component={Callback}/>
        <Route       path='/'                         component={wrappedRoutes}/>
      </Switch>
    </main>
  </MainWrapper>
);

/**
 * TODO if I want a 404 here, I shouldn't display the <Layout /> component to unauthorized users
 **/
const wrappedRoutes = () => (
  <div>
    <Layout/>
    <div className='container__wrap'>
      <Route path='/' component={Pages}/>
      {/* <Route render={page404} /> */}
      {/* <PrivateRoute component={page404} /> */}
    </div>
  </div>
);

const Pages = () => (

  <React.Suspense fallback={<CenteredLoadingIndicator />}>
    <Switch>
  {/* <Route path='/pages/one' component={ExamplePageOne}/> */}
      <PrivateRoute      path='/ac/dashboard'      component={AutocommsDashboard}      />
      <PrivateRoute      path='/ac/analytics'      component={AutocommsAnalytics}      />
      <PrivateRoute      path='/ac/detail'         component={CustomerDetailScr}       />
      <PrivateRoute      path="/ac/schedule"       component={AdminContactScheduleScr} />

      <PrivateAdminRoute path="/admin/users"       component={AdminUsers}              />
      <PrivateAdminRoute path="/admin/companies"   component={AdminCompanies}          />
      <PrivateAdminRoute path="/admin/tasks"       component={AdminTasks}              />
      <PrivateAdminRoute path="/admin/logs/usage"  component={AdminUsageEvents}        />
      <PrivateAdminRoute path="/admin/logs/audit"  component={AdminAuditEvents}        />
      <PrivateAdminRoute path="/admin/versions"    component={AdminVersionHist}        />
      <PrivateAdminRoute path="/admin/appsettings" component={AdminAppSettingsScr}     />
      {Consts.ENABLE_DEMO_SCR && <PrivateAdminRoute path="/admin/demo/new" component={AdminDemoNewScr} />}
  {/* <PrivateRoute path='/pages/one'           component={ExamplePageOne}    />
      <PrivateRoute path='/pages/two'           component={ExamplePageTwo}    /> */}
      <PrivateRoute component={page404} />
    </Switch>
  </React.Suspense>

);

export default Router;
