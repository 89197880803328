import * as React from "react";

interface ScrollIndicatorProps {
  onClick : () => void;
}

export const ScrollIndicator: React.FC<ScrollIndicatorProps> = ({onClick}) =>
  <div className="scroll__indicator">
    <a onClick={onClick}><span/></a>
  </div>
