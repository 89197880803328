import * as React                     from "react";
import Consts                         from "../shared/Consts";
import { AvailableThemes }            from "../shared/interfaces";

/**
 * This provider will hold all of the states that drive the app template
 * and used to held in Redux (e.g. theme selection, sidebar toggled state, etc.)
 **/

 //
 // Additional Types
 //

 export interface ISideBarStates {
   show    : boolean;     // show the sidebar (mobile)
   collapse: boolean;     // show the sidebar (desktop)
 }

 //
 // Types for the Wrapper Component
 //

interface AppProviderState {
   sidebarState      : ISideBarStates;
   themeClassName    : AvailableThemes;     // css class to apply to the whole site
   isDarkTheme       : boolean;
   avatarFilePath    : string;
 }

 //
 // Types for the Context
 //

export interface AppProviderCtx extends AppProviderState {
   fnChSidebarVisibility?      : () => void;
   fnChSidebarVisibilityMobile?: () => void;
   fnChThemeLight?             : () => void;
   fnChThemeDark?              : () => void;
   fnChThemeCrisp?             : () => void;
 }

const AvatarLightTheme  = `${process.env.PUBLIC_URL}/img/ava_light.png`;
const AvatarDarkTheme   = `${process.env.PUBLIC_URL}/img/ava_dark.png`;

const defaultProviderState: AppProviderCtx = {
   // it doesn't really matter what these are, as we'll never see them
   fnChSidebarVisibility       : undefined,
   fnChSidebarVisibilityMobile : undefined,
   sidebarState                : {show: false, collapse: false},
   fnChThemeLight              : undefined,
   fnChThemeDark               : undefined,
   fnChThemeCrisp              : undefined,
   themeClassName              : "theme-light",
   isDarkTheme                 : false,
   avatarFilePath              : AvatarLightTheme,
 };

 // the default doesn't have to take the shape of AppProviderState, but it's pretty convenient here to do so
export const GAppCtx: React.Context<AppProviderCtx> = React.createContext(defaultProviderState);

export const AppProvider: React.FC<{}> = (props): JSX.Element => {

  const [sidebarState, setSidebarState]       = React.useState<{show: boolean, collapse: boolean}>({show: false, collapse: false});
  const [theme, setTheme]                     = React.useState<{themeClassName     : AvailableThemes,
                                                                isDarkTheme        : boolean        , // it's useful to know if this is considered to be a dark theme or not
                                                                avatarFilePath     : string         ,
                                                               }>({themeClassName: "theme-light", isDarkTheme: false, avatarFilePath: AvatarLightTheme});

  //
  // Component
  //

  React.useEffect( () => {
    const storedTheme = localStorage.getItem(Consts.LOCALSTORE_THEME);
    if (storedTheme) {
      switch(storedTheme) {
        case "light":
          changeThemeLight();
          break;
        case "dark":
          changeThemeDark();
          break;
        case "crisp":
          changeThemeCrisp();
          break;
        default:
          changeThemeLight();
      }
    }
  }, []);

  //
  // SideBar Visibility/Mode
  //

  const changeSidebarVisibility = () => {
    console.log("[DD] Toggle SideBar (Desktop)");
    setSidebarState({
      ...sidebarState,
      collapse : !(sidebarState.collapse),
    });
  }

  const changeSidebarVisibilityMobile = () => {
    console.log("[DD] Toggle SideBar (Mobile)");
    setSidebarState({
      ...sidebarState,
      show     : !(sidebarState.show),
    });
  }

  //
  // Theme
  //

  const changeThemeDark = () => {
    console.log("[DD] Toggle Dark Theme");
    setTheme({
      themeClassName    : "theme-dark",
      isDarkTheme       : true,
      avatarFilePath    : AvatarDarkTheme,
    });
    localStorage.setItem(Consts.LOCALSTORE_THEME, "dark");
  }

  const changeThemeLight = () => {
    console.log("[DD] Toggle Light Theme");
    setTheme({
      themeClassName    : "theme-light",
      isDarkTheme       : false,
      avatarFilePath    : AvatarLightTheme,
    });
    localStorage.setItem(Consts.LOCALSTORE_THEME, "light");
  }

  const changeThemeCrisp = () => {
    console.log("[DD] Toggle Crisp Theme");
    setTheme({
      themeClassName    : "theme-crisp",
      isDarkTheme       : false,
      avatarFilePath    : AvatarLightTheme,
    });
    localStorage.setItem(Consts.LOCALSTORE_THEME, "crisp");
  }


  return(
    // GAppCtx will provide all the members of - AppProviderCtx
    <GAppCtx.Provider value={{
      fnChSidebarVisibility      : changeSidebarVisibility      ,
      fnChSidebarVisibilityMobile: changeSidebarVisibilityMobile,
      sidebarState               : sidebarState                 ,
      fnChThemeLight             : changeThemeLight             ,
      fnChThemeDark              : changeThemeDark              ,
      fnChThemeCrisp             : changeThemeCrisp             ,
      themeClassName             : theme.themeClassName         ,
      isDarkTheme                : theme.isDarkTheme            ,
      avatarFilePath             : theme.avatarFilePath         ,
    }}>
      {props.children}
    </GAppCtx.Provider>
  );
}

export default (AppProvider);
