import React            from "react";
import * as Logging     from "../shared/Logging";

/**
 * Lifted straight from the docs: https://reactjs.org/docs/concurrent-mode-suspense.html
 * They say "Don't copy-paste this into your project!"....but that could mean anything
 */
export interface SusVal<T> {read: () => T | never};
export type SuspenseStates  = "pending" | "success" | "error";
export const LogSource      = "useSuspensePromiseWrapper";
export const useSuspensePromiseWrapper = <T>() => {

  const promiseWrapper = (promise: Promise<T | undefined> | undefined, callbackSuccess?: (data?: any) => void) => {
    Logging.debug(LogSource, "Wrap promise...");
    let status: SuspenseStates = "pending";
    let result: any;
    const suspender = undefined !== promise ?
      promise.then(
        (r) => {
          status = "success";
          callbackSuccess && callbackSuccess(r);
          result = r;
        },
        (e) => {
          status = "error";
          result = e;
        },
      ) : ( () => {
        Logging.error(LogSource, "Unanticipated condition");
        status = "error";
        result = null;
      })();
    return ({
      read: () => {
        switch (status) {
          case "pending":
            throw (suspender);
          case "success":
            return (result);
          case "error":
          default:
            throw (result);
        }
      },
    });
  };

  return React.useCallback(promiseWrapper, []);
};


/**
 * Sometimes I want to reuse the suspense mechanism for values that aren't promises, but I just want to reuse
 * everything I have in place for dealing with promises.
 * e.g. a form that can handle a fetched (existing), and a static value (templated new value)
 */

 export const useSuspenseLiteralWrapper = <T>() => {

  const literalWrapper = (staticVal: T) => {

    return({
      read: () => staticVal,
    });
  }

  return React.useCallback(literalWrapper, []);
 }
