import * as React               from 'react';
import { Link }                 from 'react-router-dom';
import { GAuthCtx }             from '../../../context_providers/AuthProvider';

interface ITopbarMenuLinkLogOutProps {
  title: string;
  icon : string;
  path : string;
}

export class TopbarMenuLinkLogOut extends React.PureComponent<ITopbarMenuLinkLogOutProps> {

  public render(): JSX.Element {
    return (
      <GAuthCtx.Consumer>
        {(ctx) => (
          <Link
            className='topbar__link'
            to={this.props.path}
            onClick={ctx.fnSignOutOfSession}
          >
            <span className={`topbar__link-icon lnr lnr-${this.props.icon}`}/>
            <p className='topbar__link-title'>{this.props.title}</p>
          </Link>
        )}
      </GAuthCtx.Consumer>
    )
  }

}

export default (TopbarMenuLinkLogOut);
