import React                  from "react";
import { Route, Redirect }    from "react-router-dom";
import { GAuthCtx }           from "../context_providers/AuthProvider";

export const PrivateRoute : React.FC<any> = ({ component: Component, ...rest }): JSX.Element =>
    <GAuthCtx.Consumer>
      {(ctx) => (
          <Route {...rest} render={(props) => (
            ctx && ctx.isAuthenticated!() === true
              ? <Component {...props} />
              : <Redirect to="/log_in" />
          )} />
        )}
    </GAuthCtx.Consumer>
