import React                   from "react";

// TODO deprecate this function
export const useToggle = (initalState: boolean = false): [boolean, () => void, () => void, () => void] => {

  const [state, setState] = React.useState<boolean>(initalState);
  const toggle = () => setState( !state );

  const forceTrue  = () => setState(true);
  const forceFalse = () => setState(false);

  return[state, toggle, forceTrue, forceFalse];
}

export const useToggleVal = (initalState: boolean = false): {value: boolean, toggle: () => void, forceTrue: () => void, forceFalse: () => void, setState: React.Dispatch<React.SetStateAction<boolean>>} => {

  const [state, setState] = React.useState<boolean>(initalState);
  const toggle = () => setState( !state );

  const forceTrue  = () => setState(true);
  const forceFalse = () => setState(false);

  return ({value: state, toggle, forceTrue, forceFalse, setState});
}

export const useStringArr = (initalState: string[] = [], keepAtLeastOneValue?: boolean): {state: string[], addValue: (value: string) => void, rmValue: (value: string) => void, setValue: (value: string[]) => void} => {

  const [strArr, setStrArr] = React.useState<string[]>(initalState);

  const addValue = (value: string) => setStrArr([...strArr, value]);
  const rmValue  = (value: string) => {
    if (strArr.length > 1) {
      setStrArr(strArr.filter( (it) => it !== value ));
    }
  }
  const setValue = (value: string[]) => setStrArr([...value]);

  return({
    state: strArr,
    addValue     ,
    rmValue      ,
    setValue     ,
  });
}
