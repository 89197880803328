import React                 from "react";
import * as ReactDOM         from "react-dom";
import {BrowserRouter}       from "react-router-dom";
import App                   from "./app/App";
import ScrollToTop           from "./app/ScrollToTop";
import * as am4core          from "@amcharts/amcharts4/core";
import am4theme1             from "@amcharts/amcharts4/themes/animated";
// import am4theme2             from "@amcharts/amcharts4/themes/spiritedaway";
import "./styles/App.scss";


am4core.useTheme(am4theme1);
// am4core.useTheme(am4theme2);

function noop() {}

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
  console.warn = noop;
  //console.error = noop;
} else {
  console.log("[II] Dev logging");
}

ReactDOM.render(
  // basename='/dashboard'
  <BrowserRouter basename='/'>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>,
  document.getElementById("root") as HTMLElement,
);

