import axios                                           from "axios";
import Consts                                          from "../shared/Consts";
import { IModUserRequest,
         ISetInscopeCampaignAutoRefreshRequest,
         IAddInscopeCampaignRequest,
         IRmInscopeCampaignRequest,
         IFrceInscopeCampaignRefresh,
         ISetInscopeCampaignLoadStaticFactsRequest,
         ISetInscopeCampaignSetDisplayTypeRequest,
         ICampaignSetHiddenRequest,
         ICampaignSetUseCampaignProductRequest,
         ISetRtHierarchyRequest,
         SetSuspectClickOverrideRequest,
         AdminGetUsersResponse,
         UpdateUserRequest }                           from "../shared/interfaces_api_admin";
import { IFeedbackRequest,
         LoginResponse,
         ApiResponse }                                 from "../shared/interfaces";
import { ActionsOnSaleResponse,
         GetSettingsResponse,
         GetRawEventsForAutomotiveSaleResponse,
         SaleCustomerSalesResponse,
         SalesSummaryResponse,
         SaleTimelineResponse,
         TaskStatusAllResponse,
         TaskStatusResponse,
         GetCompaniesResponse,
         GetPlotResponse,
         AdminEventsAuditResponse,
         AdminEventsReqResponse,
         ContactScheduleResponse,
         SetDaysSpacingOnSaleRequest,
         SetCompletedSaleRequest}                 from "../shared/interfaces_api";
import { PlotId }                                      from "../shared/interfaces_api_enums";

const statusNoResponse = 440;
const ApiBaseUrl = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/v1/`;

const hdrsLogin = {
  "Content-Type": "application/json",
};

const hdrs = {
  "Content-Type": "application/json",
};

const getReqHeaders = () => {

  return({
    // 'Accept': 'application/json',
    "Content-Type"  : "application/json",
    "Authorization" : getAuthHeaderValue(),
  });
}

const getAuthHeaderValue = (): string => {

  const bearerToken = localStorage.getItem(Consts.LOCALSTORE_BEARER_TOKEN);
  return(bearerToken ? `Bearer ${bearerToken}` : "");
}

const genericGet = async <T>(url: string, params: Record<string, string | number> | null, custHeaders?: any, withCredentials?: boolean): Promise<ApiResponse<T>> => {

  const response: ApiResponse<T> = {
    success    : false,
    statusCode : statusNoResponse,
    data       : null,
  };

  const headers = custHeaders ?? getReqHeaders();

  try {
    const rResponse = await axios.get(url, {headers, params : params || undefined, withCredentials});
    if (undefined !== rResponse.data && rResponse.data.success) {
      response.success    = true;
      response.data       = rResponse.data;
      response.statusCode = 200;
    } else {
      response.statusCode = rResponse.status || 500;
    }
  } catch(err: any) {
    console.error(err);
    if (err.response) {
      response.statusCode = err.response.status ?? 1002;
      response.data       = err.response.data   || null;
    }
  }

  return(response);
}

const genericPost = async <T>(url: string, postBody: any, custHeaders?: any): Promise<ApiResponse<T>> => {

  const response: ApiResponse<T> = {
    success    : false,
    statusCode : statusNoResponse,
    data       : null,
  };

  const headers = custHeaders ?? getReqHeaders();

  try {
    const rResponse = await axios.post(url, postBody, {headers});
    if (undefined !== rResponse.data && rResponse.data.success) {
      response.success    = true;
      response.data       = rResponse.data;
      response.statusCode = 200;
    } else {
      response.statusCode = rResponse.status || 500;
    }
  } catch(err: any) {
    console.error(err);
    if (err.response) {
      response.statusCode = err.response.status ?? 1002;
      response.data       = err.response.data   || null;
    }
  }

  return(response);
}

//
// hub

export const hubTurnOffTracking = (hubRoot: string, hubSecret: string) => {

  const separator = "/" === hubRoot.charAt(hubRoot.length - 1) ? "" : "/";

  const url = `${hubRoot}${separator}api/notrack?secret=${hubSecret}`;
  return(genericGet(url, null, undefined, true));
}

//
// login

export const login = async (usrName: string, password: string): Promise<ApiResponse<LoginResponse>> => {

  const url = `${ApiBaseUrl}auth/login`;

  const data = {
      username: usrName,
      password: password,
  }

  return(genericPost(url, data, hdrsLogin));
}

//
// admin routes - users

export const adminGetUsers = async (): Promise<ApiResponse<AdminGetUsersResponse>> => {

  const url = `${ApiBaseUrl}admin/lstusr`;
  return(genericGet(url, null));
}

export const adminUpdUser = async (req: UpdateUserRequest): Promise<ApiResponse<undefined>> => {

  const url = `${ApiBaseUrl}admin/updusr`;

  return(genericPost(url, req));
}

export const adminDelUser = async (dbId: number): Promise<ApiResponse<undefined>> => {

  const url = `${ApiBaseUrl}admin/delusr`;

  return(genericPost(url, {dbId}));
}

//
// admin routes - tasks

export const adminGetTasks = async (): Promise<ApiResponse<TaskStatusAllResponse>> => {

  const url = `${ApiBaseUrl}tsk/status`;
  return(genericGet(url, null));
}

export const adminGetTask = async (taskName: string): Promise<ApiResponse<TaskStatusResponse>> => {

  const url = `${ApiBaseUrl}tsk/status/${taskName}`;
  return(genericGet(url, null));
}

export const adminRunTask = async (taskName: string): Promise<ApiResponse<{success: boolean, message: string}>> => {

  const url = `${ApiBaseUrl}tsk/start`;
  return(genericPost(url, {taskName}));
}

export const adminGetSettings = async (): Promise<ApiResponse<GetSettingsResponse>> => {

  const url = `${ApiBaseUrl}admin/settings`;
  return(genericGet(url, null));
}

//
// admin routes - actions

export const adminQueueAction = async (saleId: number, actionId: number, templateId: number): Promise<ApiResponse<{success: boolean, message: string}>> => {

  const url = `${ApiBaseUrl}admin/qaction`;
  return(genericPost(url, {saleId, actionId, templateId}));
}

//
// admin routes - logs/events

export const adminGetLogAudit = async (startDate: string, endDate: string): Promise<ApiResponse<AdminEventsAuditResponse>> => {

  const url    = `${ApiBaseUrl}admin/events/audit`;
  const params = { startDate, endDate };

  return(genericGet(url, params));
}

export const adminGetLogRequest = async (startDate: string, endDate: string): Promise<ApiResponse<AdminEventsReqResponse>> => {

  const url    = `${ApiBaseUrl}admin/events/req`;
  const params = { startDate, endDate };

  return(genericGet(url, params));
}

//
// user routes

export const usrChPassword = async (oldPassword: string, newPassword: string): Promise<ApiResponse<{success: boolean, message?: string}>> => {

  const url = `${ApiBaseUrl}user/chpass`;
  return(genericPost(url, {oldPassword, newPassword}));
}

//
// sales routes

export const salesGetSummary = async (): Promise<ApiResponse<SalesSummaryResponse>> => {

  const url = `${ApiBaseUrl}sales/summary`;
  return(genericGet(url, null));
}

export const salesGetActionsOnSale = async (saleId: number): Promise<ApiResponse<ActionsOnSaleResponse>> => {

  const url = `${ApiBaseUrl}sales/actions/${saleId}`;
  return(genericGet(url, null));
}

export const salesGetTimeline = async (saleId: number): Promise<ApiResponse<SaleTimelineResponse>> => {

  const url = `${ApiBaseUrl}sales/timeline/${saleId}?sort=desc`;
  return(genericGet(url, null));
}

export const salesGetContactSched = async (): Promise<ApiResponse<ContactScheduleResponse>> => {

  const url = `${ApiBaseUrl}sales/csched`;
  return(genericGet(url, null));
}

/**
 * __Note__ restricted to admins only
 */
export const salesSetDaysSpacingOnSale = async (req: SetDaysSpacingOnSaleRequest): Promise<ApiResponse<undefined>> => {

  const url = `${ApiBaseUrl}sales/setdays`;

  return(genericPost(url, req));
}

export const salesSetCompleteOnSale = async (req: SetCompletedSaleRequest): Promise<ApiResponse<undefined>> => {

  const url = `${ApiBaseUrl}sales/setcomplete`;

  return(genericPost(url, req));
}

//
// raw events routes

export const rawEventsGetEventsForSale = async (extTransactionKey: number): Promise<ApiResponse<GetRawEventsForAutomotiveSaleResponse>> => {

  const url = `${ApiBaseUrl}rawevents/sale/${encodeURIComponent(extTransactionKey)}`;
  return(genericGet(url, null));
}

//
// action routes

export const actionRepeat = async (actionId: number): Promise<ApiResponse<null>> => {

  const url = `${ApiBaseUrl}action/repeat/${actionId}`;
  return(genericGet(url, null));
}

/**
 * Returns a summary of all sales a customer has been involved with
 */
export const salesCustomersSales = async (customerId: number): Promise<ApiResponse<SaleCustomerSalesResponse>> => {

  const url = `${ApiBaseUrl}sales/cust/${customerId}`;
  return(genericGet(url, null));
}

//
// company routes

/**
 * __NOTE:__ admin restricted call, but otherwise identical to company/lst
 */
export const companiesGetAll = async (): Promise<ApiResponse<GetCompaniesResponse>> => {

  const url = `${ApiBaseUrl}company/lstall`;
  return(genericGet(url, null));
}

export const companiesGetForUsr = async (): Promise<ApiResponse<GetCompaniesResponse>> => {

  const url = `${ApiBaseUrl}company/lst`;
  return(genericGet(url, null));
}

export const companyEdit = async (companyId: number, prop: string, val: string): Promise<ApiResponse<{success: boolean, message: string}>> => {

  const url = `${ApiBaseUrl}company/edit`;
  return(genericPost(url, {companyId, prop, val}));
}

export const companyAdd = async (groupName: string, companyName: string): Promise<ApiResponse<{success: boolean, message: string}>> => {

  const url = `${ApiBaseUrl}company/ins`;
  return(genericPost(url, {groupName, companyName}));
}

//
// plot routes

export const getPlot = async (companyIds: number[], plot: PlotId): Promise<ApiResponse<GetPlotResponse>> => {

  const url = `${ApiBaseUrl}plots/plot`;
  return(genericPost(url, {companyIds, plot}));
}

//
// link routes

export const setProductOnLink = async (newProductName: string, urlShort: string): Promise<ApiResponse<null>> => {

  const url = `${ApiBaseUrl}link/setproduct`;
  return(genericPost(url, {newProductName, urlShort}));
}

//
// OLD DELETE ALL OF THIS SHIT!!!


export default class BackendApi {

  private static getReqHeaders = () => {

    return({
      // 'Accept': 'application/json',
      "Content-Type"  : "application/json",
      "Authorization" : BackendApi.getAuthHeaderValue(),
    });
  }

  public static getAuthHeaderValue = (): string => {

    const bearerToken = localStorage.getItem(Consts.LOCALSTORE_BEARER_TOKEN);

    return(bearerToken ? `Bearer ${bearerToken}` : "");
  }

  // > JWT-Secured Calls < //

  //
  // Static Reports
  //

  public static getStaticReportBounces = async (usrName: string, campaignId: number): Promise<any> => {

    if (!( true )) {

      const params = { user: usrName }

      const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/static/bounce/${campaignId}`;
      const hdrsLive = BackendApi.getReqHeaders();
      return axios.get(url, {headers: hdrsLive, params: params});
    } else {
      switch(campaignId) {
        case 1:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRBounces01.json`, {headers: hdrs});
        case 2:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRBounces02.json`, {headers: hdrs});
        case 3:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRBounces03.json`, {headers: hdrs});
        default:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRBounces01.json`, {headers: hdrs});
      }
    }
  }

  public static getStaticReportUnsubscribes = async (usrName: string, campaignId: number): Promise<any> => {

    if (!( true )) {

      const params = { user: usrName }

      const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/static/unsubscribe/${campaignId}`;
      const hdrsLive = BackendApi.getReqHeaders();
      return axios.get(url, {headers: hdrsLive, params: params});
    } else {
      switch(campaignId) {
        case 1:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRUnsubscribes01.json`, {headers: hdrs});
        case 2:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRUnsubscribes02.json`, {headers: hdrs});
        case 3:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRUnsubscribes03.json`, {headers: hdrs});
        default:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRUnsubscribes01.json`, {headers: hdrs});
      }
    }
  }

  public static getStaticReportOpens = async (usrName: string, campaignId: number): Promise<any> => {

    if (!( true )) {

      const params = { user: usrName }

      const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/static/open/${campaignId}`;
      const hdrsLive = BackendApi.getReqHeaders();
      return axios.get(url, {headers: hdrsLive, params: params});
    } else {
      switch(campaignId) {
        case 1:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSROpens01.json`, {headers: hdrs});
        case 2:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSROpens02.json`, {headers: hdrs});
        case 3:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSROpens03.json`, {headers: hdrs});
        default:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSROpens01.json`, {headers: hdrs});
      }
    }
  }

  public static getStaticReportSummary = async (usrName: string, campaignId: number): Promise<any> => {

    if (!( true )) {

      const params = { user: usrName }

      const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/static/summary/${campaignId}`;
      const hdrsLive = BackendApi.getReqHeaders();
      return axios.get(url, {headers: hdrsLive, params: params});
    } else {
      switch(campaignId) {
        case 1:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRSummary01.json`, {headers: hdrs});
        case 2:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRSummary02.json`, {headers: hdrs});
        case 3:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRSummary03.json`, {headers: hdrs});
        default:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRSummary01.json`, {headers: hdrs});
      }
    }
  }

  public static getStaticReportInsights = async (usrName: string, campaignId: number): Promise<any> => {

    if (!( true )) {

      const params = { user: usrName }

      const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/static/insight/${campaignId}`;
      const hdrsLive = BackendApi.getReqHeaders();
      return axios.get(url, {headers: hdrsLive, params: params});
    } else {
      switch(campaignId) {
        case 1:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRInsights01.json`, {headers: hdrs});
        case 2:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRInsights02.json`, {headers: hdrs});
        case 3:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRInsights03.json`, {headers: hdrs});
        default:
          return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRInsights01.json`, {headers: hdrs});
      }
    }
  }

  //
  // User Feedback
  //

  public static usrFeedback = async (reqBody: IFeedbackRequest): Promise<any> => {

    const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/users/feedback`;
    const hdrsLive = BackendApi.getReqHeaders();

    return axios.post(url, reqBody, {headers: hdrsLive});
  }

  //
  // Admin Functions
  //

  public static adminModUser = async (princUsrName: string, modUsrReqBody: IModUserRequest): Promise<any> => {

    const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/updusr`;
    const params   = { user: princUsrName }
    const hdrsLive = BackendApi.getReqHeaders();

    return axios.post(url, modUsrReqBody, {headers: hdrsLive, params: params});
  }

  public static adminGetUploadDdlOptions = async (usrName: string): Promise<any> => {

    if (!Consts.USE_MOCK_DATA) {

      const params = { user: usrName };

      const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/uploadddlopts`;
      const hdrsLive = BackendApi.getReqHeaders();
      return axios.get(url, {headers: hdrsLive, params: params});
    } else {
      //TODO return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRInsights.json`, {headers: hdrs});
    }
  }

  public static adminGetCampaignsInScope = async (usrName: string): Promise<any> => {

    if (!Consts.USE_MOCK_DATA) {

      const params = { user: usrName };

      const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/lstcampaigns`;
      const hdrsLive = BackendApi.getReqHeaders();
      return axios.get(url, {headers: hdrsLive, params: params});
    } else {
      //TODO return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRInsights.json`, {headers: hdrs});
    }
  }

  public static adminGetCampaignsForCompany = async (usrName: string, companyId: number): Promise<any> => {

    if (!Consts.USE_MOCK_DATA) {

      const params = { user: usrName, cmpid: companyId};

      const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/lstcampaignsforcmp`;
      const hdrsLive = BackendApi.getReqHeaders();
      return axios.get(url, {headers: hdrsLive, params: params});
    } else {
      //TODO return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRInsights.json`, {headers: hdrs});
    }
  }

  public static adminListRtCampaignIdsForParent = async (usrName: string, parentCampaignId: number): Promise<any> => {

    if (!Consts.USE_MOCK_DATA) {

      const params = { user: usrName, campid: parentCampaignId};

      const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/lstrtcampaignids`;
      const hdrsLive = BackendApi.getReqHeaders();
      return axios.get(url, {headers: hdrsLive, params: params});
    } else {
      //TODO return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRInsights.json`, {headers: hdrs});
    }
  }

  public static adminSetInscopeCampAutoRefresh = async (princUsrName: string, modReqBody: ISetInscopeCampaignAutoRefreshRequest): Promise<any> => {

    const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/campautorefresh`;
    const params   = { user: princUsrName }
    const hdrsLive = BackendApi.getReqHeaders();

    return axios.post(url, modReqBody, {headers: hdrsLive, params: params});
  }

  public static adminSetInscopeCampLoadStaticFacts = async (princUsrName: string, modReqBody: ISetInscopeCampaignLoadStaticFactsRequest): Promise<any> => {

    const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/camploadstaticfacts`;
    const params   = { user: princUsrName }
    const hdrsLive = BackendApi.getReqHeaders();

    return axios.post(url, modReqBody, {headers: hdrsLive, params: params});
  }

  public static adminSetInscopeCampDisplayType = async (princUsrName: string, modReqBody: ISetInscopeCampaignSetDisplayTypeRequest): Promise<any> => {

    const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/campdisplaytype`;
    const params   = { user: princUsrName }
    const hdrsLive = BackendApi.getReqHeaders();

    return axios.post(url, modReqBody, {headers: hdrsLive, params: params});
  }

  public static adminSetCampHidden = async (princUsrName: string, modReqBody: ICampaignSetHiddenRequest): Promise<any> => {

    const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/camphidden`;
    const params   = { user: princUsrName }
    const hdrsLive = BackendApi.getReqHeaders();

    return axios.post(url, modReqBody, {headers: hdrsLive, params: params});
  }

  public static adminSetRtHierarchy = async (princUsrName: string, modReqBody: ISetRtHierarchyRequest): Promise<any> => {

    const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/chrth`;
    const params   = { user: princUsrName }
    const hdrsLive = BackendApi.getReqHeaders();

    return axios.post(url, modReqBody, {headers: hdrsLive, params: params});
  }

  public static adminSetCampUseCampaignProduct = async (princUsrName: string, modReqBody: ICampaignSetUseCampaignProductRequest): Promise<any> => {

    const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/campusecampprod`;
    const params   = { user: princUsrName }
    const hdrsLive = BackendApi.getReqHeaders();

    return axios.post(url, modReqBody, {headers: hdrsLive, params: params});
  }

  public static adminGetCmCampaignsForBrand = async (usrName: string, brand: string): Promise<any> => {

    if (!Consts.USE_MOCK_DATA) {

      const params = {
        user : usrName,
        brand: brand,
      }

      const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/lstcmcampaigns`;
      const hdrsLive = BackendApi.getReqHeaders();
      return axios.get(url, {headers: hdrsLive, params: params});
    } else {
      //TODO return axios.get(`${process.env.PUBLIC_URL}/mocks/getSRInsights.json`, {headers: hdrs});
    }
  }

  public static adminAddInscopeCampaign = async (princUsrName: string, addReqBody: IAddInscopeCampaignRequest): Promise<any> => {

    const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/addinscpecmp`;
    const params   = { user: princUsrName }
    const hdrsLive = BackendApi.getReqHeaders();

    return axios.post(url, addReqBody, {headers: hdrsLive, params: params});
  }

  public static adminRmInscopeCampaign = async (princUsrName: string, rmReqBody: IRmInscopeCampaignRequest): Promise<any> => {

    const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/rminscpecmp`;
    const params   = { user: princUsrName }
    const hdrsLive = BackendApi.getReqHeaders();

    return axios.post(url, rmReqBody, {headers: hdrsLive, params: params});
  }

  public static adminFrceInscopeCampaignRefresh = async (princUsrName: string, campaignid: number): Promise<any> => {

    const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/forcecamprefresh`;
    const params   = { user: princUsrName }
    const hdrsLive = BackendApi.getReqHeaders();

    const reqBody: IFrceInscopeCampaignRefresh = {
        inscopecampaignid: campaignid,
      };

    return axios.post(url, reqBody, {headers: hdrsLive, params: params});
  }

  public static adminSetMagPageClickSusOverride = async (princUsrName: string, modReqBody: SetSuspectClickOverrideRequest): Promise<any> => {

    const url      = `${Consts.BACKEND_PROTO}://${Consts.BACKEND_HOST}:${Consts.BACKEND_PORT}/api/admin/magclicksusovrride`;
    const params   = { user: princUsrName }
    const hdrsLive = BackendApi.getReqHeaders();

    return axios.post(url, modReqBody, {headers: hdrsLive, params: params});
  }

}
