import React      from "react";
import Consts     from "../../shared/Consts";


/**
 * Centered loading indicator that we can use as the suspense fallback throughout the site
 */
export const CenteredLoadingIndicator: React.FC<{}> = (props): JSX.Element => {

  return(
    <div className="load-component">
      <div className='load__icon-wrap'>
        <svg className='load__icon'>
          <path fill={Consts.CHRT_COLOR_1} d='M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z'/>
        </svg>
      </div>
    </div>
  );

}
