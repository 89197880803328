import * as React               from "react";
import KeyVariantIcon           from "mdi-react/KeyVariantIcon";
import LockQuestionIcon         from "mdi-react/LockQuestionIcon";
import AccountOutlineIcon       from "mdi-react/AccountOutlineIcon";
import SecurityLockIcon         from "mdi-react/SecurityLockIcon";
import AlertIcon                from "mdi-react/AlertIcon";
import BombIcon                 from "mdi-react/BombIcon";
import InfoOutlineIcon          from "mdi-react/InfoOutlineIcon";
import { Button }               from "reactstrap";
import BackendApi, { login as apiLogin }    from "../../services/BackendApi";
import Spinner                  from "react-spinkit";
import Consts                   from "../../shared/Consts";
import { LoginResponse }        from "../../shared/interfaces";
import Notif                    from "../../shared/Notif";
import { ToastContainer }       from "react-toastify";
import ModalWrapper             from "../../components/modal/ModalWrapper";
import RstPasswordForm          from "./components/RstPasswordForm";
import { rstPassword }          from "../../services/Backend";

interface ILoginInnerProps {
  fnSetAuthSession: (loginResponse: LoginResponse) => void;
}

interface ILoginInnerState {
  attemptingLogin: boolean;
  username       : string;
  password       : string;
  usernameInErr  : boolean;
  passwordInErr  : boolean;

  rstPasswordModalIsOpen: boolean;
  submittingRstPasswdReq: boolean;
  emailToRstPassFor     : string;
}

export default class LoginInner extends React.Component<ILoginInnerProps, ILoginInnerState> {

  readonly state: ILoginInnerState = {
    attemptingLogin: false,
    username       : "",
    password       : "",
    usernameInErr  : false,
    passwordInErr  : false,

    rstPasswordModalIsOpen: false,
    submittingRstPasswdReq: false,
    emailToRstPassFor     : "",
  }

  constructor(props: any){
      super(props);
  }

  //
  // Login
  //

  private chvalUsername = (event: any) => {
    this.setState( {username: event.target.value} );
  }

  private chvalPassword = (event: any) => {
    this.setState( {password: event.target.value} );
  }

  private handleEnterKeyPress = (event: any) => {
    let keycode = event.keyCode || event.which;
    if (13 === keycode) {
      event.stopPropagation();
      event.preventDefault();
      if (!(this.state.attemptingLogin || ( !(this.state.password.trim()) || !(this.state.username.trim()) ))) {
        this.handleSubmit();
      }
    }
  }

  private handleSubmit = () => {

    this.setState({
      attemptingLogin: true,
      usernameInErr  : false,
      passwordInErr  : false,
    }, this.validateForm);
  }

  private validateForm = () => {

    const email = this.state.username.trim().toLowerCase();
    const re    = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(email)) {
      this.handleSubmit_(email, this.state.password);
    } else {
      Notif.notifRainbow(<div><InfoOutlineIcon color={Consts.LOGGING_IN_INDICATOR_COLOR} /> Your username is your email address</div>);
      this.setState({
        attemptingLogin: false,
        usernameInErr  : true,
      });
    }
  }

  private handleSubmit_ = async (cleanedUserName: string, password: string) => {

    const response = await apiLogin(cleanedUserName, password);
    if (response.success) {
      this.props.fnSetAuthSession(response.data!);
      window.location.href = "/callback";
    } else {
      this.toastToFailedLogin(response.statusCode);
    }

    this.setState( {attemptingLogin: false} );
  }

  //
  // Reset Password (via Email)
  //

  private clickForgotPasswordLink = () => {
    if (!this.state.attemptingLogin) {
      this.toggleRstPasswordModal();
    }
  }

  private toggleRstPasswordModal = () => {

    this.setState( (prevState) => ({
      rstPasswordModalIsOpen: !(prevState.rstPasswordModalIsOpen),
    }));
  }

  private submitRstPasswordReq = (email: string) => {

    this.setState({
      emailToRstPassFor     : email,
      submittingRstPasswdReq: true,
    }, this.submitRstPasswordReq_)
  }

  private submitRstPasswordReq_ = async () => {

    if (this.state.emailToRstPassFor) {

      try {
        const result = await rstPassword.submit({body: {email: this.state.emailToRstPassFor}});

        if (result.data.success) {
          Notif.notifInfo("You have been sent instructions to recover your access");
          this.setState({
            submittingRstPasswdReq: false,
            emailToRstPassFor     : ""   ,
            rstPasswordModalIsOpen: false,
          });
        } else {
          const message = result.data.message || "Error resetting password";
          Notif.notifError(<div><AlertIcon /><br/>{message}</div>);
          this.setState({
            submittingRstPasswdReq: false,
            emailToRstPassFor     : "",
          });
        }
      } catch(err: any) {
        // console.error(err)
        const message = err.response.message || "Error resetting password";
          Notif.notifError(<div><AlertIcon /><br/>{message}</div>);
          this.setState({
            submittingRstPasswdReq: false,
            emailToRstPassFor     : "",
          });
      }
    }
  }

  //
  // Common
  //

  private toastToFailedLogin = (httpStatus: number) => {

    switch (httpStatus) {
      case 403: // this is what we are expecting
      case 401: // 401 is not likely....
        Notif.notifWarn(<div><SecurityLockIcon color='#FFF'/> Incorrect credentials<br/>Please try again</div>);
        this.setState({
          passwordInErr: true,
          usernameInErr: true,
        });
        break;
      case 1000:
        Notif.notifError(<div><BombIcon color='#FFF'/> Sorry, but a non-recoverable network error has occured<br/>Please try again later</div>);
        break;
      case 1001:
        Notif.notifError(<div><BombIcon color='#FFF'/> Sorry, but a non-recoverable error occured<br/>Please try again later</div>);
        break;
      case 1002:
        Notif.notifError(<div><AlertIcon color='#FFF'/> Error connecting to server<br/>Please try again later</div>);
        break;
      default:
        Notif.notifError(<div><AlertIcon color='#FFF'/> Sorry, but an unexpected error has occured<br/>Please try again later</div>);
        break;
    }
  }

  //
  // Rendering
  //

  private renderRstPasswordModal = (): JSX.Element => {
    return(
      <ModalWrapper color='primary'
                    hideBtn={true}
                    header={false}
                    title='Forgot Password'
                    colored={true}
                    icon={LockQuestionIcon}
                    cancelButtons={false}
                    disabled={this.state.submittingRstPasswdReq}
                    isOpen={this.state.rstPasswordModalIsOpen}
                    toggleModal={this.toggleRstPasswordModal}
      >
        <RstPasswordForm
          disable={this.state.submittingRstPasswdReq}
          submitForm={this.submitRstPasswordReq}
          toggleModal={this.toggleRstPasswordModal}
        />
      </ModalWrapper>
    );
  }

  public render(): JSX.Element {
    return (
      <React.Fragment>
        {/*  we need our own toast container here, becayse Layout.tsx isn't loaded yet... */}
        <ToastContainer />
        {this.renderRstPasswordModal()}
        <form className='form'>
          <div className='form__form-group'>
            <label className='form__form-group-label'>Username</label>
            <div className='form__form-group-field'>
              {/* <div className='form__form-group-icon'>
                <AccountOutlineIcon/>
              </div> */}
              <input
                name="username"
                type="email"
                value={this.state.username}
                onChange={this.chvalUsername}
                placeholder="(Your Email)"
                disabled={this.state.attemptingLogin}
                style={this.state.usernameInErr ? Consts.inputErrorStyle : { background: "white" }}
                onKeyPress={this.handleEnterKeyPress}
              />
            </div>
          </div>
          <div className='form__form-group'>
            <label className='form__form-group-label'>Password</label>
            <div className='form__form-group-field'>
              {/* <div className='form__form-group-icon'>
                <KeyVariantIcon/>
              </div> */}
              <input
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.chvalPassword}
                placeholder="Password"
                disabled={this.state.attemptingLogin}
                style={this.state.passwordInErr ? Consts.inputErrorStyle : { background: "white" }}
                onKeyPress={this.handleEnterKeyPress}
                autoComplete="off"
              />
            </div>
            {/* <div className="account__forgot-password">
              <span className="clicky" onClick={this.clickForgotPasswordLink}>Forgot password?</span>
            </div> */}
          </div>

          <div className='form__form-group' style={{textAlign:"center"}}>
            <br/>
            <br/>
            <Button type="button"
                    style={{width:"100%", borderRadius: 0, background: "transparent", border: "1px solid #000", color: "#000", fontWeight: "bold", fontSize: "1.2em"}}
                    color="primary"
                    className='account__btn account__btn--small'
                    onClick={this.handleSubmit}
                    // disabled={this.state.attemptingLogin || ( !(this.state.password.trim()) || !(this.state.username.trim()) )}
                    disabled={this.state.attemptingLogin}
             >
               {this.state.attemptingLogin ? <Spinner name="three-bounce" color={Consts.LOGGING_IN_INDICATOR_COLOR} /> : "Login"}
           </Button>

         </div>
        {/* <div className='btn btn-outline-primary account__btn account__btn--small'>Create Account</div> */}
        </form>
      </React.Fragment>
    )
  }
}
