import React                  from "react";

import ModalConfirm from "../components/modal/ModalConfirm";

//
// Types for the Context

type RunOnEventFun = () => boolean | void | Promise<boolean> | Promise<void>;
interface DialogState {
  title      : string;
  message    : string;
  isOpen     : boolean;
  fnConfirm? : RunOnEventFun;
  fnCancel?  : RunOnEventFun;
}

interface ConfirmProviderState {

}

export interface ConfirmProviderCtx extends ConfirmProviderState {
  fnConfirmAction? : (title: string, message: string, fnConfirm: RunOnEventFun, fnCancel?: RunOnEventFun) => void;
}

const defaultProviderState: ConfirmProviderCtx = {
};

// the default doesn't have to take the shape of ConfirmProviderState, but it's pretty convenient here to do so
export const ConfirmCtx: React.Context<ConfirmProviderCtx> = React.createContext(defaultProviderState);

const LogSource = "ConfirmProvider";
export const ConfirmProvider: React.FC<{}> = React.memo( (props) => {

  const [ dialogState, setDialogState ] = React.useState<DialogState>({isOpen: false, title: "", message: ""});

  //
  // interface

  const confirmAction = (title: string, message: string, fnConfirm: RunOnEventFun, fnCancel?: RunOnEventFun) => {

    setDialogState({
      title,
      message,
      isOpen : true,
      fnConfirm,
      fnCancel,
    });
  }

  //
  // internal functions

  const closeDialog = () => {

    setDialogState({
      ...dialogState,
      isOpen    : false,
      fnConfirm : undefined,
      fnCancel  : undefined,
    });
  }

  const closeDialogWithCancel = () => {

    // Logging.debug(LogSource, `Action for (${dialogState.title}) rejected`);

    if (dialogState.fnCancel) {
      dialogState.fnCancel();
    }

    closeDialog();
  }

  const closeWithConfirm = () => {

    // Logging.debug(LogSource, `Action for (${dialogState.title}) confirmed`);

    if (dialogState.fnConfirm) {
      dialogState.fnConfirm();
    }

    closeDialog();
  }

  //
  // rendering
  return (
    <ConfirmCtx.Provider value={{
      fnConfirmAction : confirmAction,
    }}>
      {props.children}

      <ModalConfirm
        title={dialogState.title}
        color="warning"
        colored={true}
        header={false}
        isOpen={dialogState.isOpen}
        toggleModal={closeDialogWithCancel}
        fnOnCancel ={closeDialogWithCancel}
        fnOnProceed={closeWithConfirm}
        disabled={false}
      >
        {dialogState.message}
      </ModalConfirm>
    </ConfirmCtx.Provider>
  );
})
