import React                            from "react";
import {  Button,
          ButtonToolbar,
          Modal }                       from "reactstrap";
import Spinner                          from "react-spinkit";

export interface ModalConfirmProps {
  title        : string;
  color        : "primary" | "danger" | "success" | "warning";
  colored      : boolean;
  header       : boolean;
  isOpen       : boolean;
  toggleModal  : () => void;
  disabled     : boolean;

  fnOnCancel   : () => void;
  fnOnProceed  : () => void;
}

export default class ModalConfirm extends React.PureComponent<ModalConfirmProps> {

  constructor(props: ModalConfirmProps){
    super(props);
  }

  private toggle = () => {

    if (!(this.props.disabled)) {
      this.props.toggleModal();
    }
  }

  //
  // Rendering
  //

  private renderIcon = (): JSX.Element => {

    let Icon;

    switch (this.props.color) {
      case "primary":
        Icon = <span className='lnr lnr-pushpin modal__title-icon'/>;
        break;
      case "success":
        Icon = <span className='lnr lnr-thumbs-up modal__title-icon'/>;
        break;
      case "warning":
        Icon = <span className='lnr lnr-flag modal__title-icon'/>;
        break;
      case "danger":
        //Icon = <span className='lnr lnr-cross-circle modal__title-icon'/>;
        Icon = <span className='lnr lnr-trash modal__title-icon'/>;
        break;
      default:
        Icon = <span className='lnr lnr-pushpin modal__title-icon'/>;
        break;
    }

    return (Icon);
  }

  public render(): JSX.Element {

    return (
      <>
        <Modal isOpen={this.props.isOpen} toggle={this.toggle}
               className={`modal-dialog--${this.props.color} ${this.props.colored ? "modal-dialog--colored" : ""} ${this.props.header ? "modal-dialog--header" : ""}`}>
          <div className='modal__header'>
            <span className='lnr lnr-cross modal__close-btn' onClick={this.toggle}/>
            {this.props.header ? "" : this.renderIcon()}
            <h4 className='bold-text  modal__title'>{this.props.title}</h4>
          </div>


          <div className='modal__body theme-light'>
            {this.props.children}
          </div>

          <ButtonToolbar className='modal__footer'>
            <Button onClick={this.props.fnOnCancel} disabled={this.props.disabled}>Cancel</Button>{" "}
            <Button outline={this.props.colored} color={this.props.color} onClick={this.props.fnOnProceed} disabled={this.props.disabled}>Ok</Button>
          </ButtonToolbar>

          {this.props.disabled ? <div style={{marginTop: "10px"}}><Spinner name="three-bounce" color={"#ffffff"} /></div> : <div style={{marginTop: "28px"}}/> }
        </Modal>
      </>
    );
  }
}
