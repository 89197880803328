import * as React                   from "react";
import { GAuthCtx }                 from "../../context_providers/AuthProvider";
import { CenteredLoadingIndicator } from "../../components/loading/CenteredLoadingIndicator";

export class Callback extends React.PureComponent<{}> {

  public render(): JSX.Element {
    return (
      <GAuthCtx.Consumer>
        {(ctxAuth) => (
          <div className="load">
            {(undefined !== ctxAuth.handleAuthentication)? ctxAuth.handleAuthentication() : null}
            <CenteredLoadingIndicator />
          </div>
        )}
      </GAuthCtx.Consumer>
    )
  }

}

export default (Callback);
